<script>
    import { onMount } from 'svelte';
    import TextOutput from './components/outputs/TextOutput.svelte';
    import ImageOutput from './components/outputs/ImageOutput.svelte';
    import ButtonOutput from './components/outputs/ButtonOutput.svelte';
    import SelectInput from './components/inputs/SelectInput.svelte';
    import TextInput from './components/inputs/TextInput.svelte';
    import TextAreaInput from './components/inputs/TextAreaInput.svelte';
    import RadioInput from './components/inputs/RadioInput.svelte';
    import PhoneInput from './components/inputs/PhoneInput.svelte';
    import NumberInput from './components/inputs/NumberInput.svelte';
    import CheckboxInput from './components/inputs/CheckboxInput.svelte';
    import RangeInput from './components/inputs/RangeInput.svelte';
    import FileInput from './components/inputs/FileInput.svelte';
    import DateInput from './components/inputs/DateInput.svelte';
    import Custom from './components/custom/Custom.svelte';
    import {responses,merge,componentMap,global} from './stores.js';
    import {PARSELOGIC} from './cmplogic.js';
    //import { slide } from 'svelte/transition';
    
    export let component;
    export let componentIndex;
    export let pageIndex;
    export let sub = false;
    export function isValid(){
        
        if (input && input.isValid){
            return input.isValid();
        }
        return true;
    }
    let input;
    const mergePattern = /{!([0-z_.]+)}/g;
    let text;
    onMount(() => {
        text = component.text;
        if ($responses[component.id] === undefined && component.defaultValue){
        $responses[component.id] = component.defaultValue;
    }
	});
    //$:console.log(handleMerge(component,$responses,$componentMap,$global));
    // $: console.log(handleMerge(component,$responses,$componentMap,$global)) {
    //     text = handleMerge(text,component,$responses,$componentMap,$global);
    //     component.text = text;
    // }
    $: if ($responses && text){
        //console.log($responses);
        let match;

        let tmpText = text;
        //console.log(text,tmpText);
        while ((match = mergePattern.exec(text)) !== null) {
            //console.log(match);
            if (match.length > 1){
                let splt = match[1].split('.');
                if (splt[0] == 'global'){
                    let tmp = $global;
                    //console.log(tmp);
                    for(let i=1;i<splt.length;i++){
                        if (tmp)
                            tmp = tmp[splt[i]];
                        //console.log(tmp);
                    }
                    tmpText = tmpText.replace(match[0],tmp);
                } else if (splt[0] == 'this' || splt[0] == component.id){
                    if (splt.length == 1 || splt[1] == 'value'){
                        if (component.defaultValue == null && component.altText != null){
                            tmpText = component.altText
                        } else {
                            if (component.type == 'number'){
                                let toNumber = parseInt(component.defaultValue);
                                if (toNumber != NaN){
                                    let formatted = toNumber.toLocaleString();
                                    tmpText = tmpText.replace(match[0],formatted);
                                } else
                                tmpText = tmpText.replace(match[0],component.defaultValue);
                            } else if (component.type == 'phone')
                            tmpText = tmpText.replace(match[0],parsePhone(component.defaultValue));
                            else
                            tmpText = tmpText.replace(match[0],component.defaultValue);
                        }
                    } else {
                        let tmp = component;
                        for(let i=1;i<splt.length;i++){
                            if (tmp)
                                tmp = tmp[splt[i]];
                        }
                        tmpText = tmpText.replace(match[0],tmp);
                    }
                } else if ($componentMap[splt[0]]){
                    if (splt.length == 1 || splt[1] == 'value'){
                        let val = $responses[splt[0]] || 'n/a';
                        tmpText = tmpText.replace(match[0],val);
                    } else {
                        let tmp = $componentMap[splt[0]];
                        for(let i=1;i<splt.length;i++){
                            if (tmp)
                                tmp = tmp[splt[i]];
                        }
                        tmpText = tmpText.replace(match[0],tmp);
                    }
                }
            }
            //console.log(tmpText);
        }
        //console.log(tmpText);
        component.text = tmpText;
    }
    // function handleMerge(_component,$responses,$componentMap,$global){
        
    // }
    // $: if (component.text){
    //     let arr = [...component.text.matchAll(mergePattern)];
    //         arr.forEach(match=>{
    //             if (match.length > 1){
    //                 if (match[1] == 'value'){
    //                     if (component.defaultValue == null && component.altText != null){
    //                         component.text = component.altText
    //                     } else {
    //                         if (component.type == 'number'){
    //                             let toNumber = parseInt(component.defaultValue);
    //                             if (toNumber != NaN){
    //                                 let formatted = toNumber.toLocaleString();
    //                                 component.text = component.text.replace(match[0],formatted);
    //                             } else
    //                                 component.text = component.text.replace(match[0],component.defaultValue);
    //                         } else if (component.type == 'phone') {
    //                             component.text = component.text.replace(match[0],parsePhone(component.defaultValue));
    //                         } else
    //                             component.text = component.text.replace(match[0],component.defaultValue);
    //                     }
    //                 } else{
    //                     let splt = match[1].split('.');
    //                     let val = $merge;
    //                     splt.forEach(i=>{
    //                         if (val[i])
    //                             val = val[i];
    //                     });
    //                     component.text = component.text.replace(match[0],val);
    //                 }
    //             }
    //         });
    //     }
    
    $: isArray = Array.isArray(component);
    $: isOutput = (component && component.isOutput);

    $: isDisabled = (component.disabledIf)?PARSELOGIC({input:component.disabledIf,values:$responses,components:$componentMap,global:$global}):false;
    $: isHidden = PARSELOGIC({input:component.hiddenIf,values:$responses,components:$componentMap,global:$global});
    $: isRequired = component.required !== false;
    let _inputMap = {
        'text':TextInput,
        'textarea':TextAreaInput,
        'select':SelectInput,
        'radio':RadioInput,
        'checkbox':CheckboxInput,
        'range':RangeInput,
        'phone':PhoneInput,
        'number':NumberInput,
        'upload':FileInput,
        'date':DateInput

    };
    let _outputMap = {
        'text':TextOutput,
        'image':ImageOutput,
        'button':ButtonOutput
    }

    function parsePhone(phone){
        let noNum = phone.replace(/[^0-9]/g,'');
        if (noNum.length == 11 && noNum.charAt(1) == '1'){
            noNum = noNum.substring(1);
        }
        if (noNum.length == 10){
            //console.log(noNum);
            return '('+noNum.substring(0,3)+') '+noNum.substring(3,6) +'-'+noNum.substring(6);
        }
        return phone
    }
</script>
    {#if !isHidden}    
        <div class:column={sub} class:mb-5={!sub} >
            {#if isArray}
                <div class="columns">
                    {#each component as c (c.id)}
                        <svelte:self component={c} {pageIndex} {componentIndex} sub={true}/>
                    {/each}
                </div>
            {:else}
                {#if isOutput}
                    <svelte:component this={_outputMap[component.type] || Custom} {component} />
                {:else}
                    <svelte:component this={_inputMap[component.type] || Custom} {component} required={isRequired} disabled={isDisabled} bind:value={$responses[component.id]} bind:this={input}/>
                {/if}
            {/if}
        </div>
    {/if}