import { writable } from 'svelte/store';

export const responses = writable({});
export const componentMap = writable({});
export const global = writable({});

export const merge = writable({});

export const activePage = writable(0);

export const hiddenPages = writable({});

export const callbacks = writable({});