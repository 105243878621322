<script>
    import Page from "./Page.svelte";
    import Progress from "./Progress.svelte";
    import MediaQuery from "./MediaQuery.svelte";
    import { PARSELOGIC, COMPILELOGIC } from "./cmplogic.js";
    import {
        responses,
        activePage,
        hiddenPages,
        callbacks,
        merge,
        componentMap,
        global as globalStore
    } from "./stores.js";

    export let survey = {};
    export let initialResponses = {};
    export let mergeData = {};
    export let global = {};
    if (initialResponses) $responses = initialResponses;
    if (mergeData) $merge = mergeData;
    if (global) $globalStore = global;
    //export let callbacks = {};

    //$:console.log($responses)

    $: pages = hasPages ? _build(survey.pages) : [];
    const logicFields = new Set(["hiddenIf", "requiredIf", "disabledIf"]);
    function _build(el) {
        if (Array.isArray(el)) {
            return el.map((i) => {
                return _build(i);
            });
        } else if (typeof el === "object" && el !== null) {
            let ret = {};
            for (let key in el) {
                if (logicFields.has(key)) {
                    ret[key] = (typeof el[key] == 'string')?JSON.parse(el[key]):el[key];
                } else ret[key] = _build(el[key]);
            }
            if (el.id){
                $componentMap[el.id] = el;
            }
            return ret;
        }
        return el;
    }
    export let options = {};
    let _pages = [];
    $: hasPages = survey && survey.pages;
    $: isComplete = $activePage >= (hasPages ? pages.length : 1);
    $: completeScreen = survey.completeScreen || {
        id: "x_complete",
        title: "Complete",
        components: [
            {
                id: "c_0004",
                type: "text",
                text: "Thank you for your time!",
                isOutput: true,
            },
        ],
    };
    $: isMultiPage = options && options.multiPage;
    $: isIntercept = options && options.isIntercept;
    $: nextDelay =
        options && options.nextDelay != null && options.nextDelay > 0
            ? options.nextDelay
            : 0;
    $: backDelay =
        options && options.backDelay != null && options.backDelay > 0
            ? options.backDelay
            : 0;

    $: clearHidden = (options && options.clearHidden);

    $: progress = Math.floor(($activePage / pages.length) * 100);

    let pageStack = [];

    let _backLocked = false;
    function back() {
        if (!_backLocked) {
            _backLocked = true;
            setTimeout(() => {
                _backLocked = false;
            }, backDelay);
            if (pageStack.length > 0) {
                let i = pageStack.pop();
                $activePage = i;
                pageStack = pageStack;
                if ($callbacks.back) {
                    $callbacks.back.call(null, $activePage, $responses);
                }
            }
        }
    }
    let _nextLocked = false;
    function next() {
        if (!_nextLocked && !isLoading) {
            _nextLocked = true;
            setTimeout(() => {
                _nextLocked = false;
            }, nextDelay);
            if (
                hasPages &&
                $activePage < survey.pages.length &&
                checkValidity()
            ) {
                let pResponses = {};
                let p = survey.pages[$activePage];
                if (p && p.components) {
                    p.components.forEach((c) => {
                        if (c.id && $responses[c.id])
                            pResponses[c.id] = $responses[c.id];
                    });
                }
                pageStack[pageStack.length] = $activePage;
                let i = $activePage + 1;
                let end = survey.pages.length;

                while (i < end && $hiddenPages[i]) {
                    i++;
                }
                _tmpPage = i;

                if (_tmpPage < survey.pages.length) {
                    if ($callbacks.next) {
                        isLoading = true;
                        $callbacks.next.call(
                            null,
                            pageStack[pageStack.length - 1],
                            _tmpPage,
                            pResponses,
                            continueToPage
                        );
                    }
                }
            }

            if (_tmpPage >= survey.pages.length) {
                isLoading = true;
                pages.forEach((p) => {
                    if ($hiddenPages[p.id] == true && p.components) {
                        p.components.forEach((c) => {
                            $responses[c.id] = null;
                        });
                    }
                });
                if ($callbacks.finish) {
                    $callbacks.finish.call(null, $responses, () => {
                        $activePage = pages.length;
                        isLoading = false;
                    });
                }
            }
            if (!isIntercept && _tmpPage != null) continueToPage();
        }
    }
    function checkValidity() {
        if (hasPages) {
            if (isMultiPage) {
                let check = true;
                for (let i = 0; i < _pages.length; i++) {
                    let page = _pages[i];
                    if (page && page.isValid && !page.isValid()) check = false;
                }
                return check;
            } else {
                //console.log('not multipage');
                if ($activePage < _pages.length) {
                    let page = _pages[$activePage];
                    //console.log(page);
                    if (page && page.isValid) return page.isValid();
                }
            }
        }
        return true;
    }
    let _tmpPage;
    function continueToPage() {
        isLoading = false;
        if (_tmpPage != null) $activePage = _tmpPage;

        _tmpPage = null;
    }
    function submit(skip) {
        if (checkValidity()) {
            //console.log("submit");
            isLoading = true;

            pages.forEach((p) => {
                if ($hiddenPages[p.id] == true && p.components) {
                    p.components.forEach((c) => {
                        $responses[c.id] = null;
                    });
                }
            });
            if ($callbacks.finish) {
                $callbacks.finish.call(null, $responses, () => {
                    $activePage = pages.length;
                    isLoading = false;
                });
            }
        }
    }
    let submitDisabled = false;
    let isLoading;
</script>

{#if isLoading}
    <div class="cSpinner">
        <div class="loader" />
    </div>
{/if}
<MediaQuery query="(max-width: 480px)" let:matches>
    <div
        class:pb-5={!matches}
        class:pb-6={matches}
        class:mt-5={!matches}
        class:multiPage={isMultiPage}
        style="overflow-x:hidden"
    >
        {#if hasPages && !isComplete}
            {#each pages as page, pageIndex (page.id)}
                <Page
                    {page}
                    {pageIndex}
                    {isMultiPage}
                    bind:this={_pages[pageIndex]}
                />
            {/each}
        {/if}
        {#if isComplete}
            <Page page={completeScreen} pageIndex={$activePage} />
        {/if}
        {#if !isMultiPage}
            <div
                class="field is-grouped"
                class:bottomNav={matches}
                style="width:100%"
            >
                <!-- <progress class="progress is-primary is-small" value={progress} max="100">{progress}%</progress> -->
                <div class="progress">
                    <Progress {progress} />
                </div>

                {#if !isComplete}
                    <div
                        class="columns is-mobile is-gapless"
                        style="width:100%"
                    >
                        <div
                            class="column"
                            style="padding-right: .25rem !important;"
                        >
                            <button class="button is-fullwidth" on:click={back}>
                                Back
                            </button>
                        </div>
                        <div
                            class="column"
                            style="padding-left: .25rem !important;"
                        >
                            <button
                                class="button is-primary is-fullwidth"
                                on:click={next}
                            >
                                Next
                            </button>
                        </div>
                    </div>
                {/if}
            </div>
        {:else if !isComplete}
            <div class="field is-grouped" class:bottomNav={matches}>
                <p class="control" style="width:100%">
                    <button
                        class="button is-primary is-fullwidth"
                        disabled={submitDisabled}
                        on:click={submit}
                    >
                        Submit
                    </button>
                </p>
            </div>
        {/if}
    </div>
</MediaQuery>

<!-- <nav class="navbar is-light is-fixed-bottom" role="navigation" aria-label="main navigation" >
        <div class="navbar-item">
                <button class="button" on:click={console.log($responses)}>responses</button>
                <button class="button" on:click={console.log(pages)}>pages</button>
                <input class="input" bind:value={logic} />
                <button class="button" on:click={handleCompile}>compile</button>
                
        </div>             
    </nav> -->
<style>
    .bottomNav {
        position: fixed;
        width: calc(100% - 1rem);
        bottom: 0;
        left: 0;
        background: white;
        padding: 1rem 0.5rem 2rem 0.5rem;
    }
    .multiPage {
        background: #f5f5f5;
    }
    .progress {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        border-radius: 0 !important;
    }
    .progress.is-small {
        height: 0.3rem !important;
    }
    .cSpinner {
        position: fixed;
        z-index: 3;
        height: 100vh;
        width: 100vw;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #ffffffc9;
    }
</style>
