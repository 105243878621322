const FUNCTION_TYPES = ["ALL", "Logical", "Operator", "Text"];
const FUNCTION_NAMES = [
    "ADD",
    "AND",
    "BEGINS",
    "BLANKVALUE",
    "CASE",
    "CONTAINS",
    "DIVIDE",
    "EQUAL",
    "FIND",
    "GREATER",
    "GREATEREQUAL",
    "IF",
    "INCLUDES",
    "INEQUAL",
    "ISBLANK",
    "ISNULL",
    "ISNUMBER",
    "LEFT",
    "LEN",
    "LESS",
    "LESSEQUAL",
    "LOWER",
    "MID",
    "MULTIPLY",
    "NOT",
    "NULLVALUE",
    "OR",
    "POW",
    "RIGHT",
    "SUBTRACT",
    "TEXT",
    "TRIM",
    "UPPER",
    "VALUE",
    //ADD Additional at end now
    "MOD",
    "MATCHES"
];
const FUNCTIONS = {
    ADD: {
        Name: "ADD",
        Type: "Operator",
        Example: "ADD(number,number,...)",
        Arguments: function (argCount) {
            if (argCount >= 2) return true;
            return false;
        },
        Description: "Adds numerical arguments, returns the summed value",
        Function: function () {
            //console.log(arguments);
            var ret;
            if (arguments.length < 2)
                throw "ADD() must have at least 2 arguments";

            ret = arguments[0] ? arguments[0] : 0;

            for (var i = 1; i < arguments.length; i++) {
                ret += arguments[i] ? arguments[i] : 0;
            }
            return ret;
        },
    },
    AND: {
        Name: "AND",
        Type: "Logical",
        Example: "AND(logical1,logical2,...)",
        Arguments: function (argCount) {
            if (argCount >= 2) return true;
            return false;
        },
        Description:
            "Checks whether all arguments are true and returns TRUE if all arguments are true",
        Function: function () {
            //console.log(arguments);
            if (arguments.length < 2)
                throw "AND() must have at least two arguments";

            for (var i = 0; i < arguments.length; i++) {
                if (!arguments[i]) return false;
            }
            return true;
        },
    },
    BEGINS: {
        Name: "BEGINS",
        Type: "Text",
        Example: "BEGINS(text, compare_text)",
        Arguments: function (argCount) {
            if (argCount == 2) return true;
            return false;
        },
        Description:
            "Checks if text begins with specified characters and returns TRUE if it does. Otherwise returns FALSE",
        Function: function () {
            //console.log(arguments);

            if (arguments.length < 2 || arguments.length > 2)
                throw "BEGINS() must have exactly 2 arguments";

            if (arguments[0] == null) return false;

            if (typeof arguments[0] != "string")
                throw "Argument 1 must be a String";
            if (typeof arguments[1] != "string")
                throw "Argument 2 must be a String";

            return arguments[0].startsWith(arguments[1]);
        },
    },
    BLANKVALUE: {
        Name: "BLANKVALUE",
        Type: "Logical",
        Example: "BLANKVALUE(expression, substitute_expression)",
        Arguments: function (argCount) {
            if (argCount == 2) return true;
            return false;
        },
        Description:
            "Checks whether expression is blank and returns substitute_expression if it is blank. If expression is not blank, returns the original expression value.",
        Function: function () {
            //console.log(arguments);
            if (arguments.length < 2 || arguments.length > 2)
                throw "BLANKVALUE() must have exactly 2 arguments";

            return arguments[0] == null || arguments[0] == ""
                ? arguments[1]
                : arguments[0];
        },
    },
    CASE: {
        Name: "CASE",
        Type: "Logical",
        Example:
            "CASE(expression, value1, result1, value2, result2,...,else_result)",
        Arguments: function (argCount) {
            if (argCount >= 4 && argCount % 2 == 0) return true;
            return false;
        },
        Description:
            "Checks an expression against a series of values.  If the expression compares equal to any value, the corresponding result is returned. If it is not equal to any of the values, the else-result is returned",
        Function: function () {
            //console.log(arguments);

            for (var i = 1; i < arguments.length - 2; i += 2) {
                if (arguments[0] == arguments[i]) return arguments[i + 1];
            }

            return arguments[arguments.length - 1];
        },
    },
    CONTAINS: {
        Name: "CONTAINS",
        Type: "Text",
        Example: "CONTAINS(text, compare_text)",
        Arguments: function (argCount) {
            if (argCount == 2) return true;
            return false;
        },
        Description:
            "Checks if text contains specified characters, and returns TRUE if it does. Otherwise, returns FALSE",
        Function: function () {
            //console.log(arguments);
            if (arguments.length < 2 || arguments.length > 2)
                throw "CONTAINS() must have exactly 2 arguments";

            if (arguments[0] == null) return false;

            if (typeof arguments[1] != "string")
                throw "Argument 2 must be a String";
            if (Array.isArray(arguments[0]) || typeof arguments[0] == 'object'){
                let parsed = JSON.stringify(arguments[0]);
                return parsed.includes(arguments[1]);
            }

            return arguments[0].includes(arguments[1]);
        },
    },
    DIVIDE: {
        Name: "DIVIDE",
        Type: "Operator",
        Example: "DIVIDE(number,number)",
        Arguments: function (argCount) {
            if (argCount == 2) return true;
            return false;
        },
        Description:
            "Divides the first argument by the second, returns quotient",
        Function: function () {
            //console.log(arguments);
            if (arguments.length < 2 || arguments.length > 2)
                throw "DIVIDE() must have exactly 2 arguments";

            return arguments[0] / arguments[1];
        },
    },
    EQUAL: {
        Name: "EQUAL",
        Type: "Operator",
        Example: "EQUAL(number,number)",
        Arguments: function (argCount) {
            if (argCount == 2) return true;
            return false;
        },
        Description:
            "Returns true if the two arguments are equal. Returns false if they are inequal or of incompatible types",
        Function: function () {
            //console.log(arguments);
            if (arguments.length < 2 || arguments.length > 2)
                throw "EQUAL() must have exactly 2 arguments";

            return arguments[0] === arguments[1];
        },
    },
    FIND: {
        Name: "FIND",
        Type: "Text",
        Example: "FIND(search_text, text)",
        Arguments: function (argCount) {
            if (argCount == 2) return true;
            return false;
        },
        Description:
            "Returns the position of the search_text string in text (starts with 0)",
        Function: function () {
            //console.log(arguments);
            if (arguments.length < 2 || arguments.length > 2)
                throw "FIND() must have exactly 2 arguments";

            return arguments[0].indexOf(arguments[1]);
        },
    },
    GREATER: {
        Name: "GREATER",
        Type: "Operator",
        Example: "GREATER(number,number)",
        Arguments: function (argCount) {
            if (argCount == 2) return true;
            return false;
        },
        Description:
            "Returns true if the first argument is greater than the second",
        Function: function () {
            //console.log(arguments);
            if (arguments.length < 2 || arguments.length > 2)
                throw "GREATER() must have exactly 2 arguments";

            return arguments[0] > arguments[1];
        },
    },
    GREATEREQUAL: {
        Name: "GREATEREQUAL",
        Type: "Operator",
        Example: "GREATEREQUAL(number,number)",
        Arguments: function (argCount) {
            if (argCount == 2) return true;
            return false;
        },
        Description:
            "Returns true if the first argument is greater than or equal to the second",
        Function: function () {
            //console.log(arguments);
            if (arguments.length < 2 || arguments.length > 2)
                throw "GREATEREQUAL() must have exactly 2 arguments";

            return arguments[0] >= arguments[1];
        },
    },
    EQUAL: {
        Name: "EQUAL",
        Type: "Operator",
        Example: "EQUAL(number,number)",
        Arguments: function (argCount) {
            if (argCount == 2) return true;
            return false;
        },
        Description:
            "Returns true if the two arguments are equal. Returns false if they are inequal or of incompatible types",
        Function: function () {
            //console.log(arguments);
            if (arguments.length < 2 || arguments.length > 2)
                throw "EQUAL() must have exactly 2 arguments";

            return arguments[0] === arguments[1];
        },
    },
    IF: {
        Name: "IF",
        Type: "Logical",
        Example: "IF(logical_test, value_if_true, value_if_false)",
        Arguments: function (argCount) {
            if (argCount == 3) return true;
            return false;
        },
        Description:
            "Checks whether a condition is true, and returns one value if TRUE and another value if FALSE.",
        Function: function () {
            //console.log(arguments);
            if (arguments.length < 3 || arguments.length > 3)
                throw "IF() must have exactly 3 arguments";

            return arguments[0] ? arguments[1] : arguments[2];
        },
    },
    INCLUDES: {
        Name: "INCLUDES",
        Type: "Text",
        Example: "INCLUDES(multiselect_picklist_field, text_literal)",
        Arguments: function (argCount) {
            if (argCount == 2) return true;
            return false;
        },
        Description:
            "Determines if any value selected in a multi-select picklist field equals a text literal you specify.",
        Function: function () {
            //console.log(arguments);
            if (arguments.length < 2 || arguments.length > 2)
                throw "FIND() must have exactly 2 arguments";

            var arg1 = arguments[0];
            if (arg1 != null && !Array.isArray(arg1))
                arg1 = arg1.split(";");
            if (typeof arguments[1] != "string")
                throw "Argument 2 must be a String";

            return arg1 ? arg1.includes(arguments[1]) : false;
        },
    },
    INEQUAL: {
        Name: "INEQUAL",
        Type: "Operator",
        Example: "INEQUAL(number,number)",
        Arguments: function (argCount) {
            if (argCount == 2) return true;
            return false;
        },
        Description:
            "Returns true if the two arguments are inequal or of incompatible types. Returns false if they are equal",
        Function: function () {
            //console.log(arguments);
            if (arguments.length < 2 || arguments.length > 2)
                throw "INEQUAL() must have exactly 2 arguments";

            return arguments[0] !== arguments[1];
        },
    },
    ISBLANK: {
        Name: "ISBLANK",
        Type: "Logical",
        Example: "ISBLANK(expression)",
        Arguments: function (argCount) {
            if (argCount == 1) return true;
            return false;
        },
        Description:
            "Checks whether an expression is blank and returns TRUE or FALSE",
        Function: function () {
            //console.log(arguments);
            if (arguments.length < 1 || arguments.length > 1)
                throw "ISBLANK() must have exactly 1 argument";

            return arguments[0] == null || arguments[0] === "";
        },
    },
    ISNULL: {
        Name: "ISNULL",
        Type: "Logical",
        Example: "ISNULL(expression)",
        Arguments: function (argCount) {
            if (argCount == 1) return true;
            return false;
        },
        Description:
            "Checks whether an expression is null and returns TRUE or FALSE",
        Function: function () {
            //console.log(arguments);
            if (arguments.length < 1 || arguments.length > 1)
                throw "ISNULL() must have exactly 1 argument";

            return arguments[0] == null;
        },
    },
    ISNUMBER: {
        Name: "ISNUMBER",
        Type: "Logical",
        Example: "ISNUMBER(Text)",
        Arguments: function (argCount) {
            if (argCount == 1) return true;
            return false;
        },
        Description:
            "Returns TRUE if the text value is a number. Otherwise, it returns FALSE.",
        Function: function () {
            //console.log(arguments);
            if (arguments.length < 1 || arguments.length > 1)
                throw "ISNUMBER() must have exactly 1 argument";

            return !isNaN(parseInt(arguments[0]));
        },
    },
    LEFT: {
        Name: "LEFT",
        Type: "Text",
        Example: "LEFT(text, num_chars)",
        Arguments: function (argCount) {
            if (argCount == 2) return true;
            return false;
        },
        Description:
            "Returns the specified number of characters from the start of a text string",
        Function: function () {
            //console.log(arguments);
            if (arguments.length < 2 || arguments.length > 2)
                throw "LEFT() must have exactly 2 arguments";
            if (typeof arguments[0] != "string")
                throw "Argument 1 must be a String";
            if (typeof arguments[1] != "number")
                throw "Argument 2 must be a number";

            return arguments[0].substr(0, arguments[1]);
        },
    },
    LEN: {
        Name: "LEN",
        Type: "Text",
        Example: "LEN(text)",
        Arguments: function (argCount) {
            if (argCount == 1) return true;
            return false;
        },
        Description: "Returns the number of characters in a text string",
        Function: function () {
            if (arguments[0] == null) return 0;
            if (arguments.length < 1 || arguments.length > 1)
                throw "LEN() must have exactly 1 argument";
            if (typeof arguments[0] != "string")
                throw "Argument 1 must be a String";

            return arguments[0].length;
        },
    },
    LESS: {
        Name: "LESS",
        Type: "Operator",
        Example: "LESS(number,number)",
        Arguments: function (argCount) {
            if (argCount == 2) return true;
            return false;
        },
        Description:
            "Returns true if the first argument is less than the second",
        Function: function () {
            //console.log(arguments);
            if (arguments.length < 2 || arguments.length > 2)
                throw "LESS() must have exactly 2 arguments";

            return arguments[0] < arguments[1];
        },
    },
    LESSEQUAL: {
        Name: "LESSEQUAL",
        Type: "Operator",
        Example: "LESSEQUAL(number,number)",
        Arguments: function (argCount) {
            if (argCount == 2) return true;
            return false;
        },
        Description:
            "Returns true if the first argument is less than or equal to the second",
        Function: function () {
            //console.log(arguments);
            if (arguments.length < 2 || arguments.length > 2)
                throw "LESSEQUAL() must have exactly 2 arguments";

            return arguments[0] <= arguments[1];
        },
    },
    LOWER: {
        Name: "LOWER",
        Type: "Text",
        Example: "LOWER(text)",
        Arguments: function (argCount) {
            if (argCount == 1) return true;
            return false;
        },
        Description: "Converts all letters in the value to lowercase",
        Function: function () {
            //console.log(arguments);
            if (arguments.length < 1 || arguments.length > 1)
                throw "LOWER() must have exactly 1 argument";
            if (typeof arguments[0] != "string")
                throw "Argument 1 must be a String";

            return arguments[0].toLowerCase();
        },
    },
    MATCHES: {
        Name: "MATCHES",
        Type: "Text",
        Example: "MATCHES(text,regex)",
        Arguments: function (argCount) {
            if (argCount == 2) return true;
            return false;
        },
        Description: "Returns true if the value matches the provided regular expression",
        Function: function () {
            //console.log(arguments);
            if (arguments.length < 2 || arguments.length > 2)
                throw "MATCHES() must have exactly 2 argument";
            if (arguments[0] != null && arguments[1] != null && arguments[1] != ''){
                if (typeof arguments[0] != "string")
                    throw "Argument 1 must be a String";
                if (typeof arguments[1] != "string")
                    throw "Argument 2 must be a String";

                
                let regex = new RegExp(arguments[1]);
                return regex.test(arguments[0]);
            }
            return false;
        },
    },
    MID: {
        Name: "MID",
        Type: "Text",
        Example: "MID(text, start_num, num_chars)",
        Arguments: function (argCount) {
            if (argCount == 3) return true;
            return false;
        },
        Description:
            "Returns character from the middle of a text string, given a starting position (starting with 0) and length",
        Function: function () {
            //console.log(arguments);
            if (arguments.length < 3 || arguments.length > 3)
                throw "MID() must have exactly 3 arguments";
            if (typeof arguments[0] != "string")
                throw "Argument 1 must be a String";
            if (typeof arguments[1] != "number")
                throw "Argument 2 must be a number";
            if (typeof arguments[2] != "number")
                throw "Argument 3 must be a number";

            return arguments[0].substr(arguments[1], arguments[2]);
        },
    },
    MOD: {
        Name: "MOD",
        Type: "Operator",
        Example: "MOD(number,number)",
        Arguments: function (argCount) {
            if (argCount == 2) return true;
            return false;
        },
        Description:
            "Performs a modulus operation on the first argument, using the second argument",
        Function: function () {
            //console.log(arguments);
            if (arguments.length < 2 || arguments.length > 2)
                throw "MOD() must have exactly 2 arguments";
            if (arguments[0] != null && typeof arguments[0] != "number")
                throw "Argument 1 must be a number";
            if (arguments[1] != null && typeof arguments[1] != "number")
                throw "Argument 2 must be a number";

            let arg1 = arguments[0] || 0;
            let arg2 = arguments[1] || 0;
            return arg1 % arg2;
        },
    },
    MULTIPLY: {
        Name: "MULTIPLY",
        Type: "Operator",
        Example: "MULTIPLY(number,number,...)",
        Arguments: function (argCount) {
            if (argCount >= 2) return true;
            return false;
        },
        Description: "Multiplies numerical arguments, returns the product",
        Function: function () {
            //console.log(arguments);
            var ret;
            if (arguments.length < 2)
                throw "MUTLIPLY() must have at least 2 arguments";

            ret = arguments[0];

            for (var i = 1; i < arguments.length; i++) {
                ret *= arguments[i];
            }
            return ret;
        },
    },
    NOT: {
        Name: "NOT",
        Type: "Logical",
        Example: "NOT(logical)",
        Arguments: function (argCount) {
            if (argCount == 1) return true;
            return false;
        },
        Description: "Changes FALSE to TRUE or TRUE to FALSE",
        Function: function () {
            //console.log(arguments);
            if (arguments.length < 1 || arguments.length > 1)
                throw "NOT() must have exactly 1 argument";

            return !arguments[0];
        },
    },
    NULLVALUE: {
        Name: "NULLVALUE",
        Type: "Logical",
        Example: "NULLVALUE(expression, substitute_expression)",
        Arguments: function (argCount) {
            if (argCount == 2) return true;
            return false;
        },
        Description:
            "Checks whether expression is null and returns substitute_expression if it is null. If expression is not null, returns the original expression value.",
        Function: function () {
            //return () => {
            //console.log(arguments);
            if (arguments.length < 2 || arguments.length > 2)
                throw "NULLALUE() must have exactly 2 arguments";

            return arguments[0] != null ? arguments[0] : arguments[1];
            //};
        },
    },
    OR: {
        Name: "OR",
        Type: "Logical",
        Example: "OR(logical1,logical2,...)",
        Arguments: function (argCount) {
            if (argCount >= 2) return true;
            return false;
        },
        Description:
            "Checks whether any of the arguments are true and returns TRUE or FALSE. Returns FALSE only if all arguments are false",
        Function: function () {
            //return () => {
            //console.log(arguments);
            if (arguments.length < 2)
                throw "OR() must have at least two arguments";

            for (var i = 0; i < arguments.length; i++) {
                if (arguments[i]) return true;
            }
            return false;
            //};
        },
    },
    POW: {
        Name: "POW",
        Type: "Operator",
        Example: "POW(number,number)",
        Arguments: function (argCount) {
            if (argCount == 2) return true;
            return false;
        },
        Description:
            "Raises the first argument to the power of the second argument",
        Function: function () {
            //return () => {
            //console.log(arguments);
            if (arguments.length < 2 || arguments.length > 2)
                throw "POW() must have exactly 2 arguments";
            if (typeof arguments[0] != "number")
                throw "Argument 1 must be a number";
            if (typeof arguments[1] != "number")
                throw "Argument 2 must be a number";
            return Math.pow(arguments[0], arguments[1]);
            //};
        },
    },
    RIGHT: {
        Name: "RIGHT",
        Type: "Text",
        Example: "RIGHT(text, num_chars)",
        Arguments: function (argCount) {
            if (argCount == 2) return true;
            return false;
        },
        Description:
            "Returns the specified number of characters from the end of a text string",
        Function: function () {
            //return () => {
            //console.log(arguments);
            if (arguments.length < 2 || arguments.length > 2)
                throw "RIGHT() must have exactly 2 arguments";
            if (typeof arguments[0] != "string")
                throw "Argument 1 must be a String";
            if (typeof arguments[1] != "number")
                throw "Argument 2 must be a Number";

            return arguments[0].substr(arguments[2].length - arguments[1]);
            //};
        },
    },
    SUBTRACT: {
        Name: "SUBTRACT",
        Type: "Operator",
        Example: "SUBTRACT(number,number,...)",
        Arguments: function (argCount) {
            if (argCount >= 2) return true;
            return false;
        },
        Description:
            "Subtracts numerical arguments, returns the difference",
        Function: function () {
            //console.log(arguments);
            var ret;
            if (arguments.length < 2)
                throw "SUBTRACT() must have at least 2 arguments";

            ret = arguments[0];

            for (var i = 1; i < arguments.length; i++) {
                ret -= arguments[i];
            }
            return ret;
        },
    },
    TEXT: {
        Name: "TEXT",
        Type: "Text",
        Example: "TEXT(value)",
        Arguments: function (argCount) {
            if (argCount == 1) return true;
            return false;
        },
        Description:
            "Converts a value to text using standard display format",
        Function: function () {
            //console.log(arguments);
            var ret;
            if (arguments.length != 1)
                throw "TEXT() must have exactly 1 argument";
            if (typeof arguments[0] != "string")
                throw "Argument 1 must be a String";

            return arguments[0].toString();
        },
    },
    TRIM: {
        Name: "TRIM",
        Type: "Text",
        Example: "TRIM(text)",
        Arguments: function (argCount) {
            if (argCount == 1) return true;
            return false;
        },
        Description:
            "Removes all spaces from a text string except for single spaces between words",
        Function: function () {
            //console.log(arguments);
            var ret;
            if (arguments.length != 1)
                throw "TRIM() must have exactly 1 argument";
            if (typeof arguments[0] != "string")
                throw "Argument 1 must be a String";

            return arguments[0].trim();
        },
    },
    UPPER: {
        Name: "UPPER",
        Type: "Text",
        Example: "UPPER(text)",
        Arguments: function (argCount) {
            if (argCount == 1) return true;
            return false;
        },
        Description: "Converts all letters in the value to uppercase",
        Function: function () {
            //console.log(arguments);
            var ret;
            if (arguments.length != 1)
                throw "UPPER() must have exactly 1 argument";
            if (typeof arguments[0] != "string")
                throw "Argument 1 must be a String";

            return arguments[0].toUpperCase();
        },
    },
    VALUE: {
        Name: "VALUE",
        Type: "Text",
        Example: "VALUE(text)",
        Arguments: function (argCount) {
            if (argCount == 1) return true;
            return false;
        },
        Description:
            "Converts a text string that represents a number to a number",
        Function: function () {
            //console.log(arguments);
            var ret;
            if (arguments.length != 1)
                throw "VALUE() must have exactly 1 argument";
            if (typeof arguments[0] != "string")
                throw "Argument 1 must be a String";

            return parseInt(arguments[0]);
        },
    },
};
const RENDERLOGIC = (input, offset) => {
    if (input == null)
        return null;
    if (offset == null)
        offset = 0;
    //console.log(input);
    //console.log(offset);
    if (typeof input == "object") {
        if (Array.isArray(input)) {
            // is var
            if (input.length > 0) {
                var v = input[0];
                var ret = "";
                for (var i = 0; i < offset; i++) ret += "  ";
                return ret + v;
            }
        } else {
            // is function
            if (input.f != null && FUNCTION_NAMES.length > input.f) {
                var ret = "";
                for (var i = 0; i < offset; i++) ret += "  ";
                var func_name = FUNCTION_NAMES[input.f];
                ret += func_name + "(\n";
                if (
                    input.a &&
                    Array.isArray(input.a) &&
                    input.a.length > 0
                ) {
                    var args = [];
                    //console.log(input.a);
                    input.a.forEach((i) => {
                        //console.log(i);
                        args.push(RENDERLOGIC(i, offset + 1));
                    });
                    for (var i = 0; i < args.length; i++) {
                        if (i + 1 < args.length) ret += args[i] + ",\n";
                        else ret += args[i] + "\n";
                    }
                }
                for (var i = 0; i < offset; i++) ret += "  ";
                ret += ")";
                return ret;
            }
        }
    } else {
        var ret = "";
        for (var i = 0; i < offset; i++) ret += "  ";
        if (typeof input == 'string')
            input = `"${input}"`;
        return ret + input;
    }
};
const PARSELOGIC = (params) => {
    const {input,values,mapping,components,global} = params;
    //console.log(input);
    if (typeof input === "object") {
        if (input == null) {
            return false;
        } else if (Array.isArray(input)) {
            // is var
            if (input.length > 0) {
                var v = input[0];
                let splt = v.split('.');
                let checkValue = false;
                if (splt.length > 1){
                    if (splt[1] == 'value'){
                        checkValue = true;
                        v = splt[0];
                    } else if (splt[1] == 'custom' && splt.length >2){
                        if (components && components[splt[0]]){
                            let component = components[splt[0]];
                            if (component.custom && component.custom[splt[2]] != undefined)
                                return component.custom[splt[2]];
                        }
                        return null;
                    } else if (splt[0] == 'global' && splt.length == 2) {
                        return global?.[splt[1]];
                    }
                } else
                    checkValue = true;
                if (checkValue && values) {
                    var b = values[v];
                    if (
                        typeof b == "object" &&
                        mapping &&
                        Array.isArray(mapping) &&
                        mapping.length > 0
                    ) {
                        mapping.forEach((p) => {
                            if (b != null) b = b[p];
                        });
                    }
                    return b;
                }
            }
        } else {
            // is function
            if (typeof input.f == 'string' || (input.f != null && FUNCTION_NAMES.length > input.f)) {
                var func_name = (typeof input.f == 'string') ? input.f : FUNCTION_NAMES[input.f];
                //console.log('found ' + func_name + ' at ' + input.f);
                if (func_name) {
                    var func_obj = FUNCTIONS[func_name];
                    //console.log(func_obj);
                    if (func_obj.Function) {
                        if (
                            input.a &&
                            Array.isArray(input.a) &&
                            input.a.length > 0
                        )
                            var args = [];
                        input.a.map((i) => {
                            args.push(PARSELOGIC({input:i, values, mapping,components,global}));
                        });
                        //console.log(args);
                        var f = func_obj.Function;
                        //console.log(f);
                        var ret = f.apply(null, args);
                        //console.log(ret);
                        return ret;
                    }
                }
            }
        }
    } else return input;
};
const COMPILELOGIC = (input, offset) => {
    if (input == null)
        return null;
    if (offset == null)
        offset = 0;
        
    //console.log('Start Function at ' +offset + ' : ' + input);
    input = input.replace(/[\n\r]/g, "");
    input = input.trim();

    var ret, arg, func;
    var pStart, pEnd, sStart, sEnd, cIdx;
    var i, j;
    var pStk, sStk;
    var oPat =
        /^((\s*".*"\s*)|(\s*[A-Z]+\s*\(.*\)\s*)|(\s*[+ -]?[0-9]*([.][0-9]*)?\s*)|(\s*[0-z-_\.]+\s*)|(\s*true|TRUE|True\s*)|(\s*false|FALSE|False\s*)){1}(<>|<=|>=|&&|&|\|\||\/|\*|\^|%|!=|=|>|<|\+|-)((\s*".*"\s*)|(\s*[A-Z]+\s*\(.*\)\s*)|(\s*[+ -]?[0-9]*([.][0-9]*)?\s*)|(\s*[0-z-_\.]+\s*)|(\s*true|TRUE|True\s*)|(\s*false|FALSE|False\s*)){1}$/;
    var fPat = /^\s*[A-Z]+\s*\(.*\)\s*$/;
    var sPat = /^\s*".*"\s*$/;
    var nPat = /^\s*[+ -]?[0-9]*([.][0-9]*)?\s*$/;
    var vPat = /^\s*[0-z_\.]+\s*$/;
    var truePat = /^\s*true|TRUE|True\s*$/;
    var falsePat = /^\s*false|FALSE|False\s*$/;

    const arr = input.split("");

    if (oPat.test(input)) {
        var r = { a: [] };
        const m = input.match(oPat);
        if (m.length >= 11) {
            switch (m[9]) {
                case "&&":
                    r.f = FUNCTION_NAMES.indexOf("AND");
                    break;
                case "&":
                case "+":
                    r.f = FUNCTION_NAMES.indexOf("ADD");
                    break;
                case "-":
                    r.f = FUNCTION_NAMES.indexOf("SUBTRACT");
                    break;
                case "<>":
                case "!=":
                    r.f = FUNCTION_NAMES.indexOf("INEQUAL");
                    break;
                case "/":
                    r.f = FUNCTION_NAMES.indexOf("DIVIDE");
                    break;
                case "*":
                    r.f = FUNCTION_NAMES.indexOf("MULTIPLY");
                    break;
                case "^":
                    r.f = FUNCTION_NAMES.indexOf("POW");
                    break;
                case ">=":
                    r.f = FUNCTION_NAMES.indexOf("GREATEREQUAL");
                    break;
                case "<=":
                    r.f = FUNCTION_NAMES.indexOf("LESSEQUAL");
                    break;
                case ">":
                    r.f = FUNCTION_NAMES.indexOf("GREATER");
                    break;
                case "<":
                    r.f = FUNCTION_NAMES.indexOf("LESS");
                    break;
                case "||":
                    r.f = FUNCTION_NAMES.indexOf("OR");
                    break;
                case "=":
                    r.f = FUNCTION_NAMES.indexOf("EQUAL");
                    break;
                case "%":
                    r.f = FUNCTION_NAMES.indexOf("MOD");
                    break;
            }
            if (r.f != null && r.f >= 0) {
                r.a.push(COMPILELOGIC(m[1], offset));
                r.a.push(COMPILELOGIC(m[10], offset));

                ret = r;
            } else {
                throw(
                    "ERR:// at col: " +
                    offset +
                    ", " +
                    input +
                    " is not a valid expression"
                );
            }
        } else {
            throw(
                "ERR:// at col: " +
                offset +
                ", Syntax error detected at: " +
                input
            );
        }
    } else if (fPat.test(input)) {
        ret = {
            a: [],
        };
        pStk = [];
        sStk = [];
        func = "";

        for (i = 0; i < arr.length; i++) {
            if (sStk.length > 0) {
                // is string
                if (arr[i] == '"') sStk.pop();
            } else {
                // not string
                if (pStk.length > 0) {
                    // is args
                    switch (arr[i]) {
                        case "(":
                            pStk.push(i);
                            break;
                        case ")":
                            pStk.pop();
                            if (pStk.length == 0) {
                                pEnd = i;
                                if (!cIdx) {
                                    arg = input.substring(pStart + 1, i);
                                } else {
                                    arg = input.substring(cIdx + 1, i);
                                }
                                ret.a.push(COMPILELOGIC(arg, i));
                                cIdx = null;
                            }
                            break;
                        case ",":
                            if (pStk.length == 1) {
                                if (!cIdx) {
                                    arg = input.substring(pStart + 1, i);
                                } else {
                                    arg = input.substring(cIdx + 1, i);
                                }
                                ret.a.push(COMPILELOGIC(arg, i));
                                cIdx = i;
                            }
                            break;
                        case '"':
                            sStk.push(i);
                            break;
                    }
                } else {
                    // not args
                    switch (arr[i]) {
                        case "(":
                            // must be end of func prefix
                            if (ret.f == null) {
                                func = func.trim();
                                for (
                                    j = 0;
                                    j < FUNCTION_NAMES.length;
                                    j++
                                ) {
                                    // inefficiency, list is sorted so can be quicker to find.
                                    if (func == FUNCTION_NAMES[j])
                                        ret.f = j;
                                }
                                if (ret.f == null) {
                                    //TODO
                                    throw(
                                        "ERR:// at col: " +
                                        (offset + i) +
                                        ", " +
                                        func +
                                        " is not a recognized function"
                                    );
                                }
                            }
                            pStart = i; // beginning of function
                            pStk.push(i);
                            break;
                        case ")":
                            //TODO
                            throw(
                                "ERR:// at col: " +
                                (offset + i) +
                                ", imbalanced paranthesis"
                            );
                            break;
                        case '"':
                            sStart = i;
                            sStk.push(i);
                            break;
                        case ",":
                            //TODO
                            throw(
                                "ERR:// at col: " +
                                (offset + i) +
                                ', unexpected character ","'
                            );
                            break;
                        default:
                            func += arr[i];
                    }
                }
            }
        }
        if (sStk.length > 0 || pStk.length > 0)
            throw("ERR:// Invalid Syntax");
    } else if (sPat.test(input)) {
        ret = input.substring(1, input.length - 1);
    } else if (nPat.test(input)) {
        ret = Number(input);
    } else if (truePat.test(input)) {
        ret = true;
    } else if (falsePat.test(input)) {
        ret = false;
    } else if (vPat.test(input)) {
        ret = input.split(".");
    } else {
        throw("ERR:// at col: " + offset + ", invalid syntax");
    }
    //console.log('End Function with ');
    //console.log(ret);
    return ret;
};
const CREATEUUID = () => {
    // currently 36 digits
    var dt = new Date().getTime();
    var uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
        /[xy]/g,
        function (c) {
            var r = (dt + Math.random() * 16) % 16 | 0;
            dt = Math.floor(dt / 16);
            return (c == "x" ? r : (r & 0x3) | 0x8).toString(16);
        }
    );
    return uuid;
};

export {
    FUNCTION_TYPES,
    FUNCTION_NAMES,
    FUNCTIONS,
    COMPILELOGIC,
    PARSELOGIC,
    RENDERLOGIC,
    CREATEUUID
};
