<script>
    import { slide } from "svelte/transition";

    export let component;
    export let value = 0;
    export let required = true;
    export function isValid(){
        //console.log('test');
        if (required && !value){
            helpText = 'This field is required';
            invalid = true;
        }
        return !invalid;
    }
    let helpText;
    let invalid = false;
    $: if (value){
        invalid = false;
        helpText = null;
    }
</script>
<div class="field">
    <label class="label content" for={component.id}>
        {@html component.text}
        {#if !required}
        <span class="sublabel">optional</span>
    {/if}
    </label>
    <div class="control">
        <input id={component.id} class:is-danger={invalid} type="range" bind:value={value} min={component.min} max={component.max}/><output>{value}</output>
    </div>
    {#if invalid}
        <p class="help is-danger" transition:slide|local>{helpText}</p>
    {/if}
</div>
<style>
    input{
        width:calc(100% - 1.5rem);
        height:1.5rem;
    }
    
    output{
        position:absolute;
        right:0;
    }
</style>