<script>
    import SearchSelect from "./SearchSelect.svelte";
    import { callbacks } from "../../stores.js";
    import { slide } from "svelte/transition";

    export let component;
    export let value = [];
    export let disabled;
    export let required = true;
    export function isValid(){
        let _invalid  = false;
        if (required && (value == null || value.length == 0)){
            helpText = 'You must select at least one item.';
            _invalid = true;
        } else if (required && value.length > 0){
            for(let i = (value.length-1);i>=0;i--){
                if (value[i].c == 0){
                    value.splice(i,1);
                } else if (value[i].i == null || value[i].n == null){
                    helpText = 'You must select an item type for all items';
                    _invalid = true;
                    break;
                }
                
            }
            value = value;
        }
        invalid = _invalid;
        return !invalid;
    }
    let helpText;
    let invalid = false;
    
    const brandMapping = {
        'm':'Name',
        'b':'Id'
    };
    const itemTypeMapping = {
        'n':'Name',
        't':'Category__c',
        'i':'Id'
    };
    function forceNumber(evt){
        var key = evt.keyCode || e.which;
        if (key > 31 && (key < 48 || key > 57)) {
            evt.returnValue = false;
            evt.preventDefault();
        }
    }
    function addItem(){
        let tmp = value.filter(i=>{
            return i.i == null && i.b == null
        });
        if (tmp.length == 0){
            value[value.length] = {
                i:null,
                t:null,
                n:null,
                b:null,
                m:null,
                c:1
            };
        }
    }
    function deleteItem(evt){
        let t = evt.currentTarget;
        if (t){
            let idx = parseInt(t.dataset.index);
            value.splice(idx, 1);
            value = value;
        }
    }
    
</script>
<div class="field">
    <label class="label" for={component.id}>{@html component.text}
        {#if !required}
        <span class="sublabel">optional</span>
    {/if}
    </label>
    {#each value as item,idx (item.i+item.b+idx)}
        <div class="field has-addons">
            <div class="control is-expanded">
                <SearchSelect title="Item Type" placeholder="Item Type" bind:value={item} fieldMapping={itemTypeMapping} callout={$callbacks.getItemTypes}></SearchSelect>
            </div>
            <div class="control is-expanded">
                <SearchSelect title="Brand" placeholder="Brand" bind:value={item} fieldMapping={brandMapping} callout={$callbacks.getBrands}></SearchSelect>
            </div>
            <div class="control" style="max-width: 9rem;">
                <input class="input" type="number" placeholder="How Many?" on:keypress={forceNumber} bind:value={item.c}/>
            </div>
            <div class="control" >
                <button class="button" on:click={deleteItem} data-index={idx}><span class="delete"></span></button>
            </div>
        </div>
    {/each}
    <div class="control">
        <button class="button is-fullwidth" on:click={addItem}>Add Item</button>
    </div>
    {#if invalid}
        <p class="help is-danger" transition:slide|local>{helpText}</p>
    {/if}
</div>

