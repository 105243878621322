<script>
    import { onMount } from "svelte";
    export let callout;
    export let fieldMapping = {};
    export let value = {};
    export let placeholder;
    export let title;
    let _input;
    let _typingTimer;
    let _dataLoading = false;
    let _modalOpen = false;
    let _search = {};
    let _data = [];
    let id;
    $: reverseMapping = flipObject(fieldMapping);
    $: _value = value[reverseMapping['Name']];

    function flipObject(obj){
        let ret = {};
        for (let key in obj){
            ret[obj[key]]=key;
        }
        return ret;
    }
    onMount(()=>{
        var dt = new Date().getTime();
        id = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
            /[xy]/g,
            function (c) {
                var r = (dt + Math.random() * 16) % 16 | 0;
                dt = Math.floor(dt / 16);
                return (c == "x" ? r : (r & 0x3) | 0x8).toString(16);
            }
        );
    });
    function openModal(evt){
        evt.currentTarget.blur();
        _modalOpen = true;
        if (_input){
            setTimeout(()=>_input.focus(),100);
        }
    }
    function closeModal(evt){
        _data = [];
        _search = {};
        if (_typingTimer)
            clearTimeout(_typingTimer);
        _modalOpen = false;
        
    }
    function handleKeyUp(evt){
        if (_typingTimer)
            clearTimeout(_typingTimer);
            _typingTimer = setTimeout(_getData, 750);
    }
    function _getData(){
        //console.log('test');
        if (_search.Name && callout){
            _dataLoading = true;
            callout(_search.Name).then(data=>{
                _data = data;
                _dataLoading = false
            }).catch(error=>{
                console.warn(error);
            });
        }
    }
    function confirmSelect(evt){
        //TODO validate
        //value = _search;
        let tmp = value;
        for (let key in fieldMapping){
            tmp[key] = _search[fieldMapping[key]];
        }
        value = tmp;
        _data = [];
        _input.blur();
        if (_typingTimer)
            clearTimeout(_typingTimer);
        _modalOpen = false;
    }
    function clear(){
        let tmp = value;
        for (let key in fieldMapping){
            tmp[key] = undefined;
        }
        value = tmp;
        _search = {};
    }
</script>
<div class="relative">
<input class="input" type="text" {placeholder} value={_value} readonly on:focus={openModal} on:keydown={(evt)=>{evt.preventDefault}}>
{#if value.Id != null}
    <button class="delete fieldClear" on:click={clear}></button>
{/if}
</div>
<div class="modal" class:is-active={_modalOpen}>
    <div class="modal-background" on:click={closeModal}></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">{title}</p>
        <button class="delete" aria-label="close" on:click={closeModal}></button>
      </header>
      <section class="modal-card-body">
        <div class="control" class:is-loading={_dataLoading}>
            <input class="input"  disabled={_dataLoading} type="text" {placeholder} bind:value={_search.Name} on:keyup={handleKeyUp} bind:this={_input}>
        </div>
          <div class="searchSelectResults">
            {#if _data.length > 0}
                <div class="control radioButton" >
                    {#each _data as record (record.Id)}
                    <input id={record.Id} type = "radio" name={id} value={record} bind:group={_search}/>
                            <label class="button is-fullwidth" class:is-primary={_search.Id == record.Id} for={record.Id}>{record.Name}</label>
                    {/each}
                </div>
            {:else}
            <p>Begin typing, then select a value below</p>
            {/if}
          </div>
        
        
      </section>
      <footer class="modal-card-foot">
        <button class="button is-primary" on:click={confirmSelect} disabled={_search.Id == null}>Confirm</button>
        <button class="button" on:click={closeModal}>Cancel</button>
      </footer>
    </div>
  </div>
  <style>
    .searchSelectResults{
        min-height:200px;
    }
    .control.radioButton>input{
        display:none;
    }
    .radioButton>label{
        justify-content: left !important;
    }
    .relative{
        position:relative;
    }
    .fieldClear{
        position: absolute !important;
        right: .5rem;
        z-index: 2;
        top: .6rem;
    }
</style>
