<script>
    import { slide } from "svelte/transition";

    export let component;
    export let value;
    export let required = true;
    export function isValid(){
        //console.log('test');
        if (required && !value){
            helpText = 'This field is required';
            invalid = true;
        }
        return !invalid;
    }
    let helpText;
    let invalid = false;
    $: if (value){
        invalid = false;
        helpText = null;
    }

    $: answers = (component && component.answers)?((Array.isArray(component.answers))?component.answers:component.answers.split(',').map(i=>{return {label:i.trim(),value:i.trim()}})):[];
</script>
<div class="field">
    <label class="label content" for={component.id}>
        {@html component.text}
        {#if !required}
                <span class="sublabel">optional</span>
            {/if}
    </label>
    <div class="control">
        <div class="select" class:is-danger={invalid} style="width:100%">
            <select id={component.id} bind:value={value} style="width:100%">
                <option value={undefined} selected disabled>Select a value...</option>
                {#each answers as answer}
                    <option value={answer.value}>{answer.label}</option>
                {/each}
            </select>
        </div>
    </div>
    {#if invalid}
        <p class="help is-danger" transition:slide|local>{helpText}</p>
    {/if}
</div>