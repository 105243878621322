<script>
    import { slide } from "svelte/transition";
    
    export let component;
    export let value;
    export let disabled;
    export let required = true;
    export function isValid(){
        //console.log('test');
        if (required ){
            if (value){
                let splt = value.split(';');
                if (splt.length == 4) {
                    if (!splt[0]){
                        helpText1 = 'This field is required';
                        invalid1 = true;
                    } 
                    if (!splt[1]){
                        helpText2 = 'This field is required';
                        invalid2 = true;
                    }
                    if (!splt[2]){
                        helpText3 = 'This field is required';
                        invalid3 = true;
                    }
                    if (!splt[3]){
                        helpText4 = 'This field is required';
                        invalid4 = true;
                    }
                }
            } else {
                invalid1 = true;
                invalid2 = true;
                invalid3 = true;
                invalid4 = true;
                helpText1 = 'This field is required';
                helpText2 = 'This field is required';
                helpText3 = 'This field is required';
                helpText4 = 'This field is required';
            }
        }
        return !(invalid1 || invalid2 || invalid3 || invalid4);
    }
    let helpText1;
    let helpText2;
    let helpText3;
    let helpText4;
    let invalid1 = false;
    let invalid2 = false;
    let invalid3 = false;
    let invalid4 = false;
    $: if (value){
        let splt = value.split(';');
        if (splt.length == 4) {
            if (splt[0]){
                helpText1 = null;
                invalid1 = false;
            }
            if (splt[1]){
                helpText2 = null;
                invalid2 = false;
            }
            if (splt[2]){
                helpText3 = null;
                invalid3 = false;
            }
            if (splt[3]){
                helpText4 = null;
                invalid4 = false;
            }
        }
    }
    const states =[
    {"label":"Alabama","value":"AL"},
    {"label":"Alaska","value":"AK"},
    // {"label":"Alberta","value":"AB"},
    // {"label":"American Samoa","value":"AS"},
    {"label":"Arizona","value":"AZ"},
    {"label":"Arkansas","value":"AR"},
    // {"label":"Armed Forces (AE)","value":"AE"},
    // {"label":"Armed Forces Americas","value":"AA"},
    // {"label":"Armed Forces Pacific","value":"AP"},
    // {"label":"British Columbia","value":"BC"},
    {"label":"California","value":"CA"},
    {"label":"Colorado","value":"CO"},
    {"label":"Connecticut","value":"CT"},
    {"label":"Delaware","value":"DE"},
    {"label":"District Of Columbia","value":"DC"},
    {"label":"Florida","value":"FL"},
    {"label":"Georgia","value":"GA"},
    //{"label":"Guam","value":"GU"},
    {"label":"Hawaii","value":"HI"},
    {"label":"Idaho","value":"ID"},
    {"label":"Illinois","value":"IL"},
    {"label":"Indiana","value":"IN"},
    {"label":"Iowa","value":"IA"},
    {"label":"Kansas","value":"KS"},
    {"label":"Kentucky","value":"KY"},
    {"label":"Louisiana","value":"LA"},
    {"label":"Maine","value":"ME"},
    //{"label":"Manitoba","value":"MB"},
    {"label":"Maryland","value":"MD"},
    {"label":"Massachusetts","value":"MA"},
    {"label":"Michigan","value":"MI"},
    {"label":"Minnesota","value":"MN"},
    {"label":"Mississippi","value":"MS"},
    {"label":"Missouri","value":"MO"},
    {"label":"Montana","value":"MT"},
    {"label":"Nebraska","value":"NE"},
    {"label":"Nevada","value":"NV"},
    //{"label":"New Brunswick","value":"NB"},
    {"label":"New Hampshire","value":"NH"},
    {"label":"New Jersey","value":"NJ"},
    {"label":"New Mexico","value":"NM"},
    {"label":"New York","value":"NY"},
    //{"label":"Newfoundland","value":"NF"},
    {"label":"North Carolina","value":"NC"},
    {"label":"North Dakota","value":"ND"},
    //{"label":"Northwest Territories","value":"NT"},
    //{"label":"Nova Scotia","value":"NS"},
    //{"label":"Nunavut","value":"NU"},
    {"label":"Ohio","value":"OH"},
    {"label":"Oklahoma","value":"OK"},
    //{"label":"Ontario","value":"ON"},
    {"label":"Oregon","value":"OR"},
    {"label":"Pennsylvania","value":"PA"},
    //{"label":"Prince Edward Island","value":"PE"},
    {"label":"Puerto Rico","value":"PR"},
    //{"label":"Quebec","value":"PQ"},
    {"label":"Rhode Island","value":"RI"},
    //{"label":"Saskatchewan","value":"SK"},
    {"label":"South Carolina","value":"SC"},
    {"label":"South Dakota","value":"SD"},
    {"label":"Tennessee","value":"TN"},
    {"label":"Texas","value":"TX"},
    {"label":"Utah","value":"UT"},
    {"label":"Vermont","value":"VT"},
    //{"label":"Virgin Islands","value":"VI"},
    {"label":"Virginia","value":"VA"},
    {"label":"Washington","value":"WA"},
    {"label":"West Virginia","value":"WV"},
    {"label":"Wisconsin","value":"WI"},
    {"label":"Wyoming","value":"WY"},
    //{"label":"Yukon Territory","value":"YT"}
];
let stateValue;
let cityValue;
let streetValue;
let zipValue;

    if (value){
        let splt = value.split(';');
        if (splt.length == 4){
            streetValue = splt[0];
            cityValue = splt[1];
            stateValue = splt[2];
            zipValue = splt[3];
        }
    }

function handleChange(evt){
    value = streetValue+';'+cityValue+';'+stateValue+';'+zipValue;
}

</script>
<div class="field">
    <label class="label content" >{@html component.text}
        {#if !required}
        <span class="sublabel">optional</span>
    {/if}
    </label>
    <div class="control">
        <div class="columns is-multiline">
            <div class="column is-full">
                <div class="field">
                    <label class="label" for={'street'+component.id}>Street</label>
                    <input class="input" class:is-danger={invalid1} id={'street'+component.id} type="text" bind:value={streetValue} {disabled} on:change={handleChange}/>
                    {#if invalid1}
                        <p class="help is-danger" transition:slide|local>{helpText1}</p>
                    {/if}
                </div>
            </div>
            <div class="column is-half">
                <div class="field">
                    <label class="label" for={'city'+component.id}>City</label>
                    <input class="input" class:is-danger={invalid2} id={'city'+component.id} type="text" bind:value={cityValue} {disabled} on:change={handleChange}/>
                    {#if invalid2}
                        <p class="help is-danger" transition:slide|local>{helpText2}</p>
                    {/if}
                </div>
            </div>
            <div class="column is-one-quarter">
                <div class="field">
                    <label class="label" for={'state'+component.id}>State</label>
                    <div class="select" class:is-danger={invalid3} style="width:100%">
                        <select id={'state'+component.id} bind:value={stateValue} style="width:100%" on:change={handleChange}>
                            <option value={undefined} selected disabled>Select a value...</option>
                            {#each states as state}
                                <option value={state.value}>{state.label}</option>
                            {/each}
                        </select>
                    </div>
                    {#if invalid3}
                        <p class="help is-danger" transition:slide|local>{helpText3}</p>
                    {/if}
                </div>
            </div>
            <div class="column is-one-quarter">
                <div class="field">
                    <label class="label" for={'zip'+component.id}>Zip</label>
                    <input class="input" class:is-danger={invalid4} id={'zip'+component.id} type="text" bind:value={zipValue} {disabled} on:change={handleChange}/>
                    {#if invalid4}
                        <p class="help is-danger" transition:slide|local>{helpText4}</p>
                    {/if}
                </div>
            </div>
        </div>
        
    </div>
</div>