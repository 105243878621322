<svelte:options immutable />
<script>
    import {merge} from '../../stores';
    import { createEventDispatcher } from 'svelte';
    
    export let file;

    $:url = $merge.AttachmentURL + file;
    const dispatch = createEventDispatcher();
    let isLoading = false;
    function handleDelete(evt){
        isLoading = true;
        dispatch('delete', {
			value: file
		});
    }
</script>
<div class="cFilePreview">
    
    <div class="cControl">
        <button class="delete" on:click={handleDelete}></button>
    </div>
    <div class="cFileThumbnail" style="background-image:url('{url}')">
        {#if isLoading}
        <div class="cSpinner">
            <div class="loader" />
        </div>
        {/if}
    </div>
</div>
<style>
    .cFilePreview{
        margin-top:1rem;
        margin-right:.75rem;
       
    }
    .cFileThumbnail{
        height:5rem;
        width:5rem;
        border-radius:.25rem;
        background-color:#eee;
        position:relative;
        background-position: center;
        background-size: cover;
    }
    .cFilePreview>.cControl{
        text-align:right;
        padding-bottom:.25rem;
    }
    .cSpinner {
        position: absolute;
        z-index: 3;
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #ffffffc9;
    }
</style>