<script>
    import DateSelector from "./DateSelector.svelte";
    import MediaQuery from "../../MediaQuery.svelte";
    import { fly,scale,slide } from 'svelte/transition';
    import { flip } from 'svelte/animate';
    export let component;
    export let value = {};
    export let disabled;
    export let required = true;
    export function isValid(){
        if (required){
            if (value != null){
                for(let key in value){
                    if (value[key] && value[key] != '000')
                        return true;
                }
            }
            helpText = 'You must select at least one date and time slot.';
            invalid = true;
        }
        return !invalid;
    }
    let helpText;
    let invalid = false;

    let maxDate = (component.custom.comparisonValue != null)?component.custom.comparisonValue:null;
    $: if (value){
        invalid = false;
        helpText = null;
    }
    $: if (typeof value == 'string'){
        value = {};
    }
    $: valueList = Object.keys(value).sort() || [];
    
    let today = new Date();
    today = new Date(today.getFullYear(), today.getMonth(), today.getDate());

    const MONTHS = [
        {
            long: "January",
            short: "Jan",
        },
        {
            long: "February",
            short: "Feb",
        },
        {
            long: "March",
            short: "Mar",
        },
        {
            long: "April",
            short: "Apr",
        },
        {
            long: "May",
            short: "May",
        },
        {
            long: "June",
            short: "Jun",
        },
        {
            long: "July",
            short: "Jul",
        },
        {
            long: "August",
            short: "Aug",
        },
        {
            long: "September",
            short: "Sep",
        },
        {
            long: "October",
            short: "Oct",
        },
        {
            long: "November",
            short: "Nov",
        },
        {
            long: "December",
            short: "Dec",
        },
    ];
    const DAYS_OF_WEEK = [
        {
            long:'Sunday',
            short:'Sun',
        },
        {
            long:'Monday',
            short:'Mon',
        },
        {
            long:'Tuesday',
            short:'Tue',
        },
        {
            long:'Wednesday',
            short:'Wed',
        },
        {
            long:'Thursday',
            short:'Thu',
        },
        {
            long:'Friday',
            short:'Fri',
        },
        {
            long:'Saturday',
            short:'Sat',
        }
    ];
    let selectRange = {};
    let addMore = false;
    let deleteActions = {};

    function confirmDays(evt){
        console.log(selectRange);
        let min = selectRange.start;
        let max = selectRange.end || selectRange.start;
        if (min){
            let tmp = new Date(min);
            let tmpString = tmp.toISOString().substring(0,10);
            
            do{
                if (!value[tmpString])
                    value[tmpString] = '000';
                tmp = addDays(tmp,1);
                tmpString = tmp.toISOString().substring(0,10);
            } while (tmpString <= max);
            //console.log(value);
        }
        selectRange = {};
        addMore = false;
    }
    function addDays(date, days) {
        let tmp = new Date(date);
        tmp.setDate(tmp.getDate() + days);
        return tmp;
    }
    function handleTimeClick(evt){
        let t = evt.currentTarget;
        if (t){
            let day = t.dataset.date;
            let time = t.dataset.time;

        
            let tmp = value[day];
            let newString = '';
            if (time.length >= tmp.length){
                for (let i=0;i<tmp.length;i++){
                    
                    let o = tmp.charAt(i);
                    let n = time.charAt(i);

                    
                    if (n == '1'){
                        o = (o == '1')?o='0':o='1';

                    }
                    newString = newString + o;
                }
            }
            value[day] = newString;
        }
    }
    function openDelete(evt){
        let t = evt.currentTarget;
        if (t){
            let d = t.dataset.day;
            deleteActions[d] = true;
            setTimeout(() => {
                console.log(d);
                if (deleteActions[d]){
                    deleteActions[d] = false;;
                }
            }, 2100);
        }
    }
    function doDelete(evt){
        let t = evt.currentTarget;
        if (t){
            let d = t.dataset.day;
            delete value[d];
            value = value;
        }
    }
</script>
<label class="label content">{@html component.text}
    {#if !required}
    <span class="sublabel">optional</span>
{/if}
</label>
<br/>
{#if valueList.length > 0 && !addMore}
    <label class="label content">What times work for you?</label>
    <MediaQuery query="(max-width: 480px)" let:matches>
    {#each valueList as day (day)}
    <div class="columns is-gapless is-mobile" style="align-items:center;width:100%" in:fly="{{x:200,duration:200}}" out:scale|local="{{duration:200}}" animate:flip="{{duration: 200}}">
        <div class="column is-narrow" style="position:relative">
            {#if matches && deleteActions[day]}
                <button class="button is-fullwidth is-danger deleteButton" style="height:100%;" transition:fly|local="{{x:-100,duration:250}}" data-day={day} on:click={doDelete}>
                    <svg class="icon"xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M16 9v10H8V9h8m-1.5-6h-5l-1 1H5v2h14V4h-3.5l-1-1zM18 7H6v12c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7z"/></svg>
                  </button>
            {/if}
            <div class="dayPreview" on:click={openDelete} data-day={day}>
                <div class="day has-text-primary-dark">
                    {#if matches}
                        {DAYS_OF_WEEK[new Date(day).getUTCDay()].short}
                    {:else}
                        {DAYS_OF_WEEK[new Date(day).getUTCDay()].long}
                    {/if}
                </div>
                <div class="date">
                    {#if matches}
                        {MONTHS[new Date(day).getUTCMonth()].short} {new Date(day).getUTCDate()}
                    {:else}
                        {MONTHS[new Date(day).getUTCMonth()].long} {new Date(day).getUTCDate()}
                    {/if}
                </div>
            </div>
            
        </div>
        <div class="column">
            <div style="display:flex;justify-content:flex-end">
                <div class="field is-grouped">
                    <div class="control">
                        <div class="field has-addons" >
                            <p class="control">
                            <button class="button" class:is-primary={value[day] && value[day].length >=3 && value[day].charAt(2)=='1'} data-date={day} data-time="001" on:click={handleTimeClick}>
                                {#if matches}
                                8-12
                                {:else}
                                8am - 12pm
                                {/if}
                            </button>
                            </p>
                            <p class="control">
                            <button class="button" class:is-primary={value[day] && value[day].length >=3 && value[day].charAt(1)=='1'} data-date={day} data-time="010" on:click={handleTimeClick}>
                                {#if matches}
                                12-3
                                {:else}
                                12pm - 3pm
                                {/if}
                            </button>
                            </p>
                            <p class="control">
                            <button class="button" class:is-primary={value[day] && value[day].length >=3 && value[day].charAt(0)=='1'} data-date={day} data-time="100" on:click={handleTimeClick}>
                            {#if matches}
                                3-6
                                {:else}
                                3pm - 6pm
                                {/if}
                            </button>
                            </p>
                        </div>
                    </div>
                    {#if !matches}
                    <div class="control">
                        <div class="field">
                            <button class="button is-danger is-outlined" data-day={day} on:click={doDelete}>
                                <svg class="icon is-small" xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M16 9v10H8V9h8m-1.5-6h-5l-1 1H5v2h14V4h-3.5l-1-1zM18 7H6v12c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7z"/></svg>
                              </button>
                            </div>
                    </div>
                    {/if}
                </div>
            </div>
            
        </div>
    </div>

    
    {/each}
    </MediaQuery>
    <button class="button" on:click={()=>{addMore = true;}}>Add More Days</button>
{:else}
    <label class="label content">What days work for you?</label>
    <DateSelector bind:value={selectRange} placeholder="Select a Date Range" max={maxDate} min={today.toISOString().substring(0,10)} disabledDays={valueList}/>
    <button class="button" on:click={confirmDays}>Confirm Days</button>
{/if}
{#if invalid}
<p class="help is-danger" transition:slide|local>{helpText}</p>
{/if}
<style>
    .dayPreview .day{
        font-size:1.5rem;
    }
    .deleteButton{
        position:absolute !important;
        top:0;
        left:0;
    }
</style>
