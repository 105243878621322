<script>
    import DateSelector from "./DateSelector.svelte";
    import MediaQuery from "../../MediaQuery.svelte";
    import { slide } from "svelte/transition";

    // import dayjs from '../../dayjs';
    export let component;
    export let value = []; // availabilities
    let _value = {};
    export let disabled;
    export let required = true;
    
    export function isValid(){
        if (required){
            if (value != null && value.length > 0){
                // TODO
                let max;
                value.forEach(i=>{
                    let end = new Date(i.End_Date_Time__c);
                    if (end){
                        if (max == null || end.getTime() > max.getTime())
                            max = end;
                    }
                    
                });
                if (max){
                    max = getAdjustedTime(max).toISOString().substring(0,10);
                    let limit = addDays(getAdjustedTime(new Date()),1).toISOString().substring(0,10);
                    if (max <= limit){
                        // helpText = 'We see the availability we currently have recorded for you is expiring soon, please provide more options';
                        helpText = 'The availability you provided does not exceed the 24 hour limit.';
                        invalid = true;
                    } else {
                        invalid = false;
                        helpText = null;
                    }
                } else {
                    helpText = 'You must select at least one date and time slot.';
                    invalid = true;
                }
            } else {
            helpText = 'You must select at least one date and time slot.';
            invalid = true;
            }
        }
        return !invalid;
    }
    let helpText;
    let invalid = false;

    $: if (value){
        invalid = false;
        helpText = null;
    }

    let w = 0;
    $: h = w/7;
    
    // START DATE STUFF
    const maxWeeks = 3;
    function addDays(date, days) {
        let tmp = new Date(date);
        tmp.setDate(tmp.getDate() + days);
        return tmp;
    }
    function getAdjustedTime(_d){
        if (typeof _d == 'string')
            _d = new Date(_d);
        let offset = getTimezoneOffset(_d);
        _d.setTime(_d.getTime() + (offset*60*60*1000));
        return _d;
    }
    function getTimezoneOffset(_d){
        let ret = -5;
        let jan = new Date(_d.getFullYear(), 0, 1);
        let jul = new Date(_d.getFullYear(), 6, 1);
        if(_d.getTimezoneOffset() < Math.max(jan.getTimezoneOffset(), jul.getTimezoneOffset()))
            ret =  -4;

            console.log(ret);
        return ret;
    }
    let today = new Date();
    
    let maxDate = addDays(today,maxWeeks*7);
    
    

    const MONTHS = [
        {
            long: "January",
            short: "Jan",
        },
        {
            long: "February",
            short: "Feb",
        },
        {
            long: "March",
            short: "Mar",
        },
        {
            long: "April",
            short: "Apr",
        },
        {
            long: "May",
            short: "May",
        },
        {
            long: "June",
            short: "Jun",
        },
        {
            long: "July",
            short: "Jul",
        },
        {
            long: "August",
            short: "Aug",
        },
        {
            long: "September",
            short: "Sep",
        },
        {
            long: "October",
            short: "Oct",
        },
        {
            long: "November",
            short: "Nov",
        },
        {
            long: "December",
            short: "Dec",
        },
    ];
    const DAYS_OF_WEEK = [
        {
            long:'Sunday',
            short:'Sun',
        },
        {
            long:'Monday',
            short:'Mon',
        },
        {
            long:'Tuesday',
            short:'Tue',
        },
        {
            long:'Wednesday',
            short:'Wed',
        },
        {
            long:'Thursday',
            short:'Thu',
        },
        {
            long:'Friday',
            short:'Fri',
        },
        {
            long:'Saturday',
            short:'Sat',
        }
    ];
    
    
    
    
    let months = [];
    let adjustedValue = [];
    $:{
        if (value && value.length){
            adjustedValue = value.filter(i=>{return i.Active__c}).map((i,index)=>{
            let adjustedStart = getAdjustedTime(i.Start_Date_Time__c);
            let adjustedEnd = getAdjustedTime(i.End_Date_Time__c);
            return {
                start:{
                    date:adjustedStart.toISOString().substring(0,10),
                    get readableDate(){
                        let _tmp = new Date(this.date+`T${this.time.toString().padStart(2,'0')}:00:00.000+0000`);
                        return `${DAYS_OF_WEEK[_tmp.getUTCDay()].short} ${MONTHS[_tmp.getUTCMonth()].short} ${_tmp.getUTCDate()}`;
                    },
                    time:adjustedStart.getUTCHours(),
                    get readableTime(){
                        let ret = '';
                        ret += (this.time > 12)?this.time-12:this.time;
                        ret += (this.time >= 12)?'PM':'AM';
                        return ret;
                    }
                },
                end:{
                    date:adjustedEnd.toISOString().substring(0,10),
                    get readableDate(){
                        let _tmp = new Date(this.date+`T${this.time.toString().padStart(2,'0')}:00:00.000+0000`);
                        return `${DAYS_OF_WEEK[_tmp.getUTCDay()].short} ${MONTHS[_tmp.getUTCMonth()].short} ${_tmp.getUTCDate()}`;
                    },
                    time:adjustedEnd.getUTCHours(),
                    get readableTime(){
                        let ret = '';
                        ret += (this.time > 12)?this.time-12:this.time;
                        ret += (this.time >= 12)?'PM':'AM';
                        return ret;
                    }
                },
                active:i.Active__c,
                id: i.Id,
                index
            };
        });
        } else {
            adjustedValue = [];
        }
    }
    $: {
        
        let month = today.getMonth();
        let year = today.getFullYear();
        months = [];
        do{
            //console.log(`${year} : ${month} - ${maxDate.getFullYear()} : ${maxDate.getMonth()}`);
            
            let firstDayOfMonth = new Date(year, month, 1);
            let weeks = [];
            let firstDay =
                firstDayOfMonth.getDay() == 0
                    ? new Date(firstDayOfMonth)
                    : addDays(firstDayOfMonth, 0 - firstDayOfMonth.getDay());
            let week = [];
            for (let i = 0; i < 42; i++) {
                if (week.length == 7) {
                    weeks.push(week);
                    week = [];
                }
                let d = addDays(firstDay, i);
                let key = d.toISOString().substring(0,10);
                let isMonth = d.getMonth() == month;
                let isDisabled = (key< today.toISOString().substring(0,10) || key>maxDate.toISOString().substring(0,10));
                let data = [];
                if (isMonth && !isDisabled){
                    data = adjustedValue.filter(i=>{
                        //console.log(key,i);
                        return (key >= i.start.date && key <= i.end.date);
                    });
                }
                let day = {
                    key,
                    isDisabled,
                    isToday: (key == today.toISOString().substring(0,10)),
                    d:d.getUTCDate(),
                    date: d.getDate(),
                    day:d.getDay(),
                    month: d.getMonth(),
                    isMonth,
                    data // todo add data
                };
                week.push(day);
                // if (selectedDay && day.key == selectedDay.key)
                //     selectedDay = day;
            }
            weeks.push(week);
            weeks = weeks;
            months[months.length] = {
                weeks,
                month:month,
                year:year 
            };
            month++;
            if (month > 11){
                month = 0;
                year++;
            }
        } while(
            (year.toString()+month.toString().padStart(2,'0')) <= 
            (maxDate.getFullYear().toString()+maxDate.getMonth().toString().padStart(2,'0'))
        );
        //console.log(months);
    }
    let selectedDay;
    let selectedData = [];
    $: {
        
        if(selectedDay){
            let data = adjustedValue.filter(i=>{
                return (selectedDay.key >= i.start.date && selectedDay.key <= i.end.date);
            });
            selectedData = data.map(i=>{
                let ret =  {
                    start:(selectedDay.key == i.start.date)?i.start.time:8,
                    end:(selectedDay.key == i.end.date)?i.end.time:18,
                    active:i.active,
                    isPrevious:i.start.date < selectedDay.key,
                    isNext:i.end.date > selectedDay.key,
                    data:i,
                };
                
                ret.style = `top:${(ret.start-8)*80}px;height:${(ret.end-ret.start)*80}px`;
                //console.log(ret);
                return ret;
            });
        }else {
            selectedData = [];
        }
    } 
    let selectedEvent;
    let _eventDate;
    let _eventStart;
    let _eventEnd;
    function selectEvent(evt){
        selectedEvent = evt;
        _eventDate = {
            start:selectedEvent.start.date,
            end:selectedEvent.end.date
        }
        _eventStart = selectedEvent.start.time;
        _eventEnd = selectedEvent.end.time;
    }
    function cancelEvent(){
        selectedEvent = null;
        _eventDate = null;
        _eventStart = null;
        _eventEnd = null;
        errors = [];
    }
    const timeOptions = [
        {
            label: '8AM',
            value:8
        },
        {
            label: '9AM',
            value:9
        },
        {
            label: '10AM',
            value:10
        },
        {
            label: '11AM',
            value:11
        },
        {
            label: '12PM',
            value:12
        },
        {
            label: '1PM',
            value:13
        },
        {
            label: '2PM',
            value:14
        },
        {
            label: '3PM',
            value:15
        },
        {
            label: '4PM',
            value:16
        },
        {
            label: '5PM',
            value:17
        },
        {
            label: '6PM',
            value:18
        },
    ];
    let errors = [];
    function handleConfirm(evt){
        // let max = _eventDate.end.split('-');
        // let maxOffset = getTimezoneOffset(new Date(max[0],max[1],max[2]));

        // max = `${_eventDate.end}T${(_eventEnd-maxOffset).toString().padStart(2,'0')}:00:00.000+0000`;
        // let min = _eventDate.start.split('-');
        // let minOffset = getTimezoneOffset(new Date(min[0],min[1],min[2]));
        // min = `${_eventDate.start}T${(_eventStart-minOffset).toString().padStart(2,'0')}:00:00.000+0000`;
        errors = [];
        let min = (_eventDate.start + _eventStart.toString().padStart(2,'0'));
        let max = (_eventDate.end + _eventEnd.toString().padStart(2,'0'));
        adjustedValue.forEach(i=>{
            console.log(i.index + ' : ' + selectedEvent.index);
            if (i.index != selectedEvent.index){
                let _compareStart = (i.start.date + i.start.time.toString().padStart(2,'0'));
                let _compareEnd = (i.end.date + i.end.time.toString().padStart(2,'0'));

                if ((_compareStart >= min && _compareStart < max) || (_compareEnd > min && _compareEnd <= max)){
                    //within bounds
                    errors[errors.length] = i;
                }
            }
        });
        if (errors.length == 0){
            let tmp;
            if (selectedEvent.index != null){
                tmp = value[selectedEvent.index];
            } else {
                tmp = {
                    Active__c: true,
                }
            }

            let end = _eventDate.end.split('-');
            let maxOffset = getTimezoneOffset(new Date(end[0],Number(end[1])-1,end[2]));
            end = `${_eventDate.end}T${(_eventEnd-maxOffset).toString().padStart(2,'0')}:00:00.000+0000`;
            let start = _eventDate.start.split('-');
            let minOffset = getTimezoneOffset(new Date(start[0],Number(start[1])-1,start[2]));
            start = `${_eventDate.start}T${(_eventStart-minOffset).toString().padStart(2,'0')}:00:00.000+0000`;
            tmp.End_Date_Time__c = end;
            tmp.Start_Date_Time__c = start;
            if (selectedEvent.index)
                value[selectedEvent.index] = tmp;
            else
                value[value.length] = tmp;
            console.log('updated',value);
            cancelEvent();
            selectedDay = selectedDay;
        }
        console.log(errors);
    }
    function handleDelete(){
        let tmp = value[selectedEvent.index];
        if (tmp.Id){
            tmp.Active__c = false;
            value[selectedEvent.index] = tmp;
        } else {
            value.splice(selectedEvent.index,1);
            value = value;
        }

        cancelEvent();
        selectedDay = selectedDay;
    }
    $:deletedDisabled = (!selectedEvent || selectedEvent.index == null);
    function addTime(evt){
        let t = evt.currentTarget;
        if (t){
            console.log(`Adding to ${t.dataset.time}`);
            selectedEvent = {
                    start:{
                    date:selectedDay.key,
                    get readableDate(){
                        let _tmp = new Date(this.date+`T${this.time.toString().padStart(2,'0')}:00:00.000+0000`);
                        return `${DAYS_OF_WEEK[_tmp.getUTCDay()].short} ${MONTHS[_tmp.getUTCMonth()].short} ${_tmp.getUTCDate()}`;
                    },
                    time:parseInt(t.dataset.time),
                    get readableTime(){
                        let ret = '';
                        ret += (this.time > 12)?this.time-12:this.time;
                        ret += (this.time >= 12)?'PM':'AM';
                        return ret;
                    }
                },
                end:{
                    date:selectedDay.key,
                    get readableDate(){
                        let _tmp = new Date(this.date+`T${this.time.toString().padStart(2,'0')}:00:00.000+0000`);
                        return `${DAYS_OF_WEEK[_tmp.getUTCDay()].short} ${MONTHS[_tmp.getUTCMonth()].short} ${_tmp.getUTCDate()}`;
                    },
                    time:parseInt(t.dataset.time)+1,
                    get readableTime(){
                        let ret = '';
                        ret += (this.time > 12)?this.time-12:this.time;
                        ret += (this.time >= 12)?'PM':'AM';
                        return ret;
                    }
                },
                active:true,
                id: null,
                index:null
            };
            _eventDate = {
                start:selectedEvent.start.date,
                end:selectedEvent.end.date
            }
            _eventStart = selectedEvent.start.time;
            _eventEnd = selectedEvent.end.time;
        }
    }
</script>
<div class="field">
{#if selectedDay == null}
    <label class="label">{@html component.text}
        {#if !required}
        <span class="sublabel">optional</span>
        {/if}
    </label>
    {#if invalid}
        <p class="help is-danger" transition:slide|local>{helpText}</p>
    {/if}
    <div class="columns is-multiline mt-5">
    {#each months as m}
    <div class="column is-half">
        <div class="cMonth " bind:clientWidth={w}>
            <div style="text-align:center;width:100%;color:#999">
                {MONTHS[m.month].long} {m.year}
            </div>
            <br />
            <div class="cCalendar" >
                <div class="cHead columns is-mobile is-gapless">
                    <div class="column">
                        <div>
                        Sun
                        </div>
                    </div>
                    <div class="column"><div>Mon</div></div>
                    <div class="column"><div>Tue</div></div>
                    <div class="column"><div>Wed</div></div>
                    <div class="column"><div>Thu</div></div>
                    <div class="column"><div>Fri</div></div>
                    <div class="column"><div>Sat</div></div>
                </div>
                
                {#each m.weeks as _w}
                    <div class="cWeek columns is-mobile is-gapless">
                        {#each _w as _d}
                            <div class="column" style="height:{h}px">
                                {#if _d.isMonth}
                                    <div class="cDay" class:disabled={_d.isDisabled} class:today={_d.isToday} class:hasData={_d.data && _d.data.length > 0} data-date={_d.key} on:click={()=>{
                                        console.log('clicked',_d);
                                        selectedDay = _d
                                        }}>
                                        <span>{_d.d}</span>
                                    </div>
                                    {:else}
                                    <div class="cDay">
                                    </div>
                                {/if}
                            </div>
                        {/each}
                        </div>
                {/each}
                </div>
        </div>
        </div>
    {/each}
    </div>
{/if}
{#if selectedDay != null}
<main style="position:relative">
    <div class="cDayHeader">
        
        <button class="button is-ghost" style="position:absolute;left:.25rem" on:click={()=>{selectedDay = null;}}>Back to Month</button>
        <div>
            <div class="pr-5">
            <button class="button is-white" on:click={()=>{selectedDay = null;}}>{DAYS_OF_WEEK[selectedDay.day].short} {MONTHS[selectedDay.month].short} {selectedDay.date}</button>
            </div>
        </div>
    </div>
    <table class="cSchedule">
        <tr class="cBorder">
            <td class="cTimeSlot">
                <span>8:00 AM</span>
            </td>
            <td style="position:relative" on:click={addTime} data-time="8">
                {#each selectedData as event}
                    <div class="cEvent" style={event.style} class:prev={event.isPrevious} class:next={event.isNext} on:click={(evt)=>{evt.stopPropagation(); selectEvent(event.data);}}>
                        <span> {event.data.start.readableTime} - {event.data.end.readableTime}</span>
                    </div>
                {/each}
            </td>
        </tr>
        <tr class="cDashedBorder">
            <td class="cTimeSlot"></td>
            <td on:click={addTime} data-time="8"></td>
        </tr>
        <tr class="cBorder">
            <td class="cTimeSlot"><span>9:00 AM</span></td>
            <td on:click={addTime} data-time="9"></td>
        </tr>
        <tr class="cDashedBorder">
            <td class="cTimeSlot"></td>
            <td on:click={addTime} data-time="9"></td>
        </tr>
        <tr class="cBorder">
            <td class="cTimeSlot"><span>10:00 AM</span></td>
            <td on:click={addTime} data-time="10"></td>
        </tr>
        <tr class="cDashedBorder">
            <td class="cTimeSlot"></td>
            <td on:click={addTime} data-time="10"></td>
        </tr>
        <tr class="cBorder">
            <td class="cTimeSlot"><span>11:00 AM</span></td>
            <td on:click={addTime} data-time="11"></td>
        </tr>
        <tr class="cDashedBorder">
            <td class="cTimeSlot"></td>
            <td on:click={addTime} data-time="11"></td>
        </tr>
        <tr class="cBorder">
            <td class="cTimeSlot"><span>12:00 PM</span></td>
            <td on:click={addTime} data-time="12"></td>
        </tr>
        <tr class="cDashedBorder">
            <td class="cTimeSlot"></td>
            <td on:click={addTime} data-time="12"></td>
        </tr>
        <tr class="cBorder">
            <td class="cTimeSlot"><span>1:00 PM</span></td>
            <td on:click={addTime} data-time="13"></td>
        </tr>
        <tr class="cDashedBorder">
            <td class="cTimeSlot"></td>
            <td on:click={addTime} data-time="13"></td>
        </tr>
        <tr class="cBorder">
            <td class="cTimeSlot"><span>2:00 PM</span></td>
            <td on:click={addTime} data-time="14"></td>
        </tr>
        <tr class="cDashedBorder">
            <td class="cTimeSlot"></td>
            <td on:click={addTime} data-time="14"></td>
        </tr>
        <tr class="cBorder">
            <td class="cTimeSlot"><span>3:00 PM</span></td>
            <td on:click={addTime} data-time="15"></td>
        </tr>
        <tr class="cDashedBorder">
            <td class="cTimeSlot"></td>
            <td on:click={addTime} data-time="15"></td>
        </tr>
        <tr class="cBorder">
            <td class="cTimeSlot"><span>4:00 PM</span></td>
            <td on:click={addTime} data-time="16"></td>
        </tr>
        <tr class="cDashedBorder">
            <td class="cTimeSlot"></td>
            <td on:click={addTime} data-time="16"></td>
        </tr>
        <tr class="cBorder">
            <td class="cTimeSlot"><span>5:00 PM</span></td>
            <td on:click={addTime} data-time="17"></td>
        </tr>
        <tr class="cDashedBorder">
            <td class="cTimeSlot"></td>
            <td on:click={addTime} data-time="17"></td>
        </tr>
        <tr class="cBorder">
            <td class="cTimeSlot" style="border-right:none;"><span>6:00 PM</span></td>
            <td></td>
        </tr>
    </table>
    
</main>
    {#if selectedEvent != null}
        <div class="modal is-active">
            <div class="modal-background"></div>
            <div class="modal-content">
                <div class="box">
                    {#if !deletedDisabled}
                        <div class="mb-5" style="text-align:right">
                            <button class="button" on:click={handleDelete}>Delete</button>
                        </div>
                    {/if}
                    
                    <p class="content">
                        Select your availability. If your selection spans multiple days, availability in the middle of the range will be considered 8AM to 6PM.
                    </p>
                    <div class="field">
                        <label class="label content">Date</label>
                        <DateSelector bind:value={_eventDate} min={today.toISOString().substring(0,10)} max={maxDate.toISOString().substring(0,10)}></DateSelector>
                    </div>
                    <div class="columns is-mobile">
                        <div class="column">
                            <div class="field">
                                <label class="label content">Start</label>
                                <div class="select" style="width:100%">
                                    <select style="width:100%" bind:value={_eventStart}>
                                        <option value="" disabled selected="selected">Select...</option>
                                        {#each timeOptions as option}
                                            <option value={option.value}>{option.label}</option>
                                        {/each}
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="column">
                            <div class="field">
                                <label class="label content">End</label>
                                <div class="select" style="width:100%">
                                    <select style="width:100%" bind:value={_eventEnd}>
                                        <option value="" disabled selected="selected">Select...</option>
                                        {#each timeOptions as option}
                                            <option value={option.value}>{option.label}</option>
                                        {/each}
                                    </select>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                    {#if errors.length >0}
                    <div class="has-text-danger mb-3">
                        The selected availability overlaps with the following:
                        <ul>
                            {#each errors as e}
                                <li>
                                    {e.start.readableDate} {e.start.readableTime} - {e.end.readableDate} {e.end.readableTime}
                                </li>
                            {/each}
                        </ul>
                    </div>
                    {/if}
                    <button class="button" on:click={cancelEvent}>Cancel</button>
                    <button class="button is-primary" on:click={handleConfirm}>Confirm</button>
                </div>
            </div>
        </div>
    {/if}
{/if}
{#if invalid}
        <p class="help is-danger" transition:slide|local>{helpText}</p>
    {/if}
</div>
<style>
    .cCalendar{
        color:#999 !important;
        table-layout:fixed;
    }
    .cCalendar .columns{
        margin-bottom:0 !important;
    }
    .cHead>.column>div{
        align-items:center;
        justify-content: center;
        display:flex;
    }
    .cDay{
        align-items:center;
        justify-content: center;
        display:flex;
        height:100%;
        cursor:pointer;
    }
    .cDay.today{
       color:#1f3f72 !important;
       font-weight:bold;
    }
    .cDay:hover{
        transform:scale(1.1);
    }
    .cDay.disabled{
        pointer-events:none;
        opacity:.5;
    }
    .cMonth{
        margin-top:1rem;

    }
    .cMonth:first-child{
        margin-top:0;
    }
    .cDay>span{
        position:relative;
    }
    .cDay.hasData>span::after{
        position:absolute;
        content:'';
        width:.4rem;
        height:.4rem;
        border-radius:.2rem;
        top:-.4rem;
        right:-.5rem;
        background:#1f3f72;
    }
    .cDayHeader{
        width: 100%;
        height: 3rem;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 .5rem;
    }
    .cSchedule{
        border-collapse:collapse;
        table-layout: fixed;
        margin-top:3rem;
        width:100%;
    }
    .cSchedule td{
        background:white;
    }
    .cTimeSlot{
        width:80px;
        height:40px;
        border-right:1px solid #eee;
        border-top:none !important;
        font-size:1rem;
        position:relative;
    }
    .cTimeSlot>span{
        position:absolute;
        top:-.5rem;
    }
    .cBorder>td{
        border-top:1px solid #eee;
    }
    .cDashedBorder>td{
        border-top:1px dashed #eee;
    }
    .cEvent{
        position:absolute;
        left:0;
        width:100%;
        background: #2b559633;
        border: 1px solid #2b5596;
        display:flex;
        align-items:center;
    }
    .cEvent>span{
        width:100%;
        text-align:center;
        font-size:1rem;
    }
    .cEvent.prev{
        border-top:none;
    }
    .cEvent.next{
        border-bottom:none;
    }
</style>