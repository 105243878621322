<script>
    import Component from './Component.svelte';
    import {responses, activePage, hiddenPages,global,componentMap} from './stores.js';
    import MediaQuery from "./MediaQuery.svelte";
    import {PARSELOGIC} from './cmplogic.js';
    import { fly } from "svelte/transition";
    

    export let page;
    export let pageIndex;
    export let isMultiPage;
    export function isValid(){
        //console.log('enter page isValid');
        if (components && components.length >0){
            let check = true;
            components.forEach(cmp=>{
                if (cmp && cmp.isValid && !cmp.isValid())
                    check = false;
            });
            return check;
        }
        return true;
    }
    let components = [];

    $: isActive = isMultiPage || $activePage == pageIndex;
    $: isHidden = PARSELOGIC({input:page.hiddenIf,values:$responses,components:$componentMap,global:$global});
    $: hasComponents = (page && page.components);

    $: {
        $hiddenPages[pageIndex] = isHidden || false;
    }
</script>
    {#if isActive && !isHidden}
    <MediaQuery query="(max-width: 480px)" let:matches>
        <div class="box mb-5 py-5" in:fly="{{ x: 400, duration: 250 }}" class:noShadow={matches} class:multiPage={isMultiPage}>
            <!-- {page.id} -->
            {#if hasComponents}
                {#each page.components as component, componentIndex (component.id)}
                    <Component {component} {pageIndex} {componentIndex} bind:this={components[componentIndex]}/>
                {/each}
            {/if}
        </div>
    </MediaQuery>
    {/if}

    <style>
        .noShadow{
            box-shadow:none !important;
        }
    </style>