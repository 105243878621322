<script>
    import { fly } from 'svelte/transition';
    import { createEventDispatcher } from 'svelte';

	const dispatch = createEventDispatcher();
    let today = new Date();
    export let start;
    export let end;
    export let disabledDays = [];

    export let min;
    export let max;

    $:console.log(min);
    const MONTHS = [
        {
            long: "January",
            short: "Jan",
        },
        {
            long: "February",
            short: "Feb",
        },
        {
            long: "March",
            short: "Mar",
        },
        {
            long: "April",
            short: "Apr",
        },
        {
            long: "May",
            short: "May",
        },
        {
            long: "June",
            short: "Jun",
        },
        {
            long: "July",
            short: "Jul",
        },
        {
            long: "August",
            short: "Aug",
        },
        {
            long: "September",
            short: "Sep",
        },
        {
            long: "October",
            short: "Oct",
        },
        {
            long: "November",
            short: "Nov",
        },
        {
            long: "December",
            short: "Dec",
        },
    ];
    let w = 0;
    $: h = w/7;
    let month = today.getMonth();
    let year = today.getFullYear();
    //$: daysInMonth = new Date(year, month + 1, 0).getDate();
    $: todayDate = today.toISOString().substring(0,10);
    $: firstDayOfMonth = new Date(year, month, 1);
    let weeks = [];
    $: {
        weeks = [];
        let firstDay =
            firstDayOfMonth.getDay() == 0
                ? new Date(firstDayOfMonth)
                : addDays(firstDayOfMonth, 0 - firstDayOfMonth.getDay());
        let week = [];
        for (let i = 0; i < 42; i++) {
            if (week.length == 7) {
                weeks.push(week);
                week = [];
            }
            let d = addDays(firstDay, i);
            week.push({date:d,string:d.toISOString().substring(0,10)});
        }
        weeks.push(week);
        weeks = weeks;
        //console.log(weeks);
    }
    function addDays(date, days) {
        let tmp = new Date(date);
        tmp.setDate(tmp.getDate() + days);
        return tmp;
    }
    let x = 300;
    function increaseMonth(evt){
        x = 300;
        evt.preventDefault();
        month++;
        if (month >= 12){
            month = 0;
            year++;
        }
    }
    function decreaseMonth(evt){
        x = -300;
        evt.preventDefault();
        month--;
        if (month <= 0){
            month = 11;
            year--;
        }
    }
    function selectDate(evt){
        evt.preventDefault();
        let t = evt.currentTarget;
        let d = t.dataset.date;
        if (d){
            //console.log(d);
            if (start && !end){
                    if (d >= start){
                        end = d;
                        dispatch('select', {});
                    } else {
                        start  = d;
                    }
                
            } else {
                start = d;
                end = null;
            }
        }
    }
</script>
{#key month}
<div in:fly="{{x:x,duration:250}}" >
    <div class="columns is-mobile is-gapless" style="margin-bottom:0">
        <div class="column is-narrow">
            <button class="button is-small is-white" on:mousedown={decreaseMonth}>&lt;</button>
        </div>
        <div class="column">
            <div style="display:flex;justify-content:center;align-items:center;height:100%">
                {MONTHS[month].long} {year}
            </div>
            
        </div>
        <div class="column is-narrow">
            <button class="button is-small is-white" on:mousedown={increaseMonth}>&gt;</button>
        </div>
    </div>
    <br />
    <div class="cCalendar" bind:clientWidth={w}>
        <div class="cHead columns is-mobile is-gapless">
            <div class="column">
                <div>
                Sun
                </div>
            </div>
            <div class="column"><div>Mon</div></div>
            <div class="column"><div>Tue</div></div>
            <div class="column"><div>Wed</div></div>
            <div class="column"><div>Thu</div></div>
            <div class="column"><div>Fri</div></div>
            <div class="column"><div>Sat</div></div>
        </div>
        
        {#each weeks as w}
            <div class="cWeek columns is-mobile is-gapless">
                {#each w as d}
                    <div class="column" style="height:{h}px" class:inRange={d.string >= start && d.string <= end} class:start={d.string == start} class:end={d.string == end}>
                        <div class="cDay" class:disabled={((min && d.string < min) || disabledDays.includes(d.string)) || (max && d.string > max)} class:inactive={d.date.getMonth() != month} class:today={d.string == todayDate} class:selected={d.string == start || d.string == end} data-date={d.string} on:click={selectDate} >
                            {d.date.getDate()}
                        </div>
                    </div>
                {/each}
                </div>
        {/each}
        </div>
</div>
{/key}
<style>
    .cCalendar{
        width:calc(100% + 2.5rem);
        margin:0 -1.25rem;
        color:#999 !important;
        table-layout:fixed;
    }
    .cCalendar .columns{
        margin-bottom:0 !important;
    }
    .cHead>.column>div{
        align-items:center;
        justify-content: center;
        display:flex;
    }
    .cDay{
        align-items:center;
        justify-content: center;
        display:flex;
        height:100%;
        cursor:pointer;
    }
    .cDay.inactive{
        color:#99999955;
    }
    :not(.inRange):not(.start):not(.end)>.cDay.today{
       color:#1f3f72 !important;
       font-weight:bold;
    }
    
    .cDay.selected{
        background:#1f3f72 !important;
        color:#fff;
        border-radius:100%;
    }
    .cDay:hover{
        transform:scale(1.1);
    }
    .cDay.disabled{
        background:#f4f4f4;
        pointer-events:none;
        opacity:.5;
    }
    .column.inRange{
        background:#2b5597;
        color:#fff;
    }
    .column.end{
        border-top-right-radius: 100%;
        border-bottom-right-radius: 100%;;
    }
    .column.start{
        border-top-left-radius: 100%;
        border-bottom-left-radius: 100%;;
    }
</style>
