<script>
    import MediaQuery from "../../MediaQuery.svelte";
    import Calendar from "./Calendar.svelte";
    import { fly,fade } from 'svelte/transition';

    export let placeholder;
    export let value = {};
    export let min;
    export let max;
    export let disabledDays = [];

    let input;
    let isOpen = false;
    let top = 0;
    let left = 0;
    let popup;
    let start = value.start;
    let end = (value.end)?value.end:value.start;
    const dateOptions = { weekday: 'short', month: 'short', day: 'numeric' };
    $: inputValue = (value.start)?((value.end && value.end != value.start)?new Date(value.start).toUTCString().slice(0, -18) + ' - ' + new Date(value.end).toUTCString().slice(0, -18):new Date(value.start).toUTCString().slice(0, -18)):undefined;
    function handleFocusIn(evt){
        evt.preventDefault();
        isOpen = true;
        if (input){
            let boundingBox = input.getBoundingClientRect();
            //console.log(boundingBox);
            if (boundingBox){
                left = boundingBox.x;
                top = boundingBox.y+boundingBox.height;
            }
        }
    }
    function handleFocusOut(evt){
        isOpen = false;
        cancel();
    }
    $:{
        if (popup && popup.focus){
            popup.focus();
        }
            
    }
    function confirm(evt){
        //evt.preventDefault();
        // console.log(start);
        // console.log(end);
        value = {
            start,end
        };
        isOpen = false;
    }
    function cancel(evt){
        //evt.preventDefault();
        if (value){
            start = value.start;
            if (value.end)
                end = value.end;
            else
                end = value.start;
        }
        isOpen = false;
    }
</script>
<div class="field">
    <div class="control has-icons-right"  >
        <input class="input" type="text" readonly on:click={handleFocusIn} on:keydown={(evt)=>{evt.preventDefault();}} bind:this={input} bind:value={inputValue} {placeholder}/>
            <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="-8 -8 40 40" width="24px" fill="#000000" class="icon is-small is-right"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M7 11h2v2H7v-2zm14-5v14c0 1.1-.9 2-2 2H5c-1.11 0-2-.9-2-2l.01-14c0-1.1.88-2 1.99-2h1V2h2v2h8V2h2v2h1c1.1 0 2 .9 2 2zM5 8h14V6H5v2zm14 12V10H5v10h14zm-4-7h2v-2h-2v2zm-4 0h2v-2h-2v2z"/></svg>
    </div>
    
    <MediaQuery query="(max-width: 480px)" let:matches>
        {#if isOpen}
            {#if matches}
            <div class="modal is-active">
                <div class="modal-background" transition:fade="{{duration:250}}" on:mousedown={cancel}></div>
                <div class="modal-content" style="max-height:unset;overflow-x:hidden" transition:fly="{{y:500,duration:250}}">
                    <div class="box">
                        <Calendar bind:start={start} bind:end={end} {min}{max} {disabledDays} on:select={confirm}></Calendar>
                        <button class="button is-white has-text-primary	" on:mousedown={confirm}>Confirm</button>
                        <button class="button is-white" on:mousedown={cancel}>Cancel</button>
                    </div>
                </div>
                
            </div>
            {:else}
                    <div class="box calendarPopup" style="top:{top}px;left:{left}px;overflow:hidden" on:focusout={handleFocusOut} bind:this={popup} tabindex="-1">
                        <Calendar bind:start={start} bind:end={end} {min}{max} {disabledDays} on:select={confirm}></Calendar>
                        <button class="button is-white has-text-primary	" on:mousedown={confirm}>Confirm</button>
                        <button class="button is-white" on:mousedown={cancel}>Cancel</button>
                    </div>
            {/if}
        {/if}
    </MediaQuery>
</div>

<style>
    .calendarPopup{
        position:absolute;
        z-index:5;
        width:300px;
    }
</style>
