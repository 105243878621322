<script>
    import ItemSelectorInput from './ItemSelectorInput.svelte';
    //import FileInput from '../inputs/FileInput.svelte';
    import AddressInput from './AddressInput.svelte';
    import WindowInput from './WindowInput.svelte';
    import DateTimeInput from './DateTimeInput.svelte';

    export let component;
    export let disabled;
    export let value;
    export function isValid(){
        if (input && input.isValid)
            return input.isValid();
    }
    let input;

    let _map = {
        'itemselector':ItemSelectorInput,
        // 'file':FileInput,
        // 'Photo':FileInput,
        'address':AddressInput,
        'Address':AddressInput,
        'Window':WindowInput,
        'window':WindowInput,
        'Availability':DateTimeInput,
        'availability':DateTimeInput
    };
</script>

<svelte:component this={_map[component.type]} {component} {disabled} bind:value={value} bind:this={input}/>