<script>
    export let component;
    $: isLink = (component.action == 'link');
    $: isSubmit = (component.action == 'submit');

    function handleClick(){
        if (isSubmit){
            console.log('submit');
        }
    }
</script>
<div class="field">
    <div class="control">
        {#if isLink}
        <a class="button is-fullwidth" href={component.href}>{component.text}</a>
    {:else}
        <button class="button is-fullwidth" on:click|once={handleClick}>{component.text}</button>
    {/if}
    </div>
</div>