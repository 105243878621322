<script>
    import { callbacks } from "../../stores.js";
    import FileInputItem from "./FileInputItem.svelte";
    import MediaQuery from "../../MediaQuery.svelte";
    import { fly,scale,slide } from 'svelte/transition';
    import { flip } from 'svelte/animate';

    export let component;
    export let value = [];
    export let disabled;
    export let required = true;
    export function isValid(){
        //console.log('test');
        if (required && (!value || (Array.isArray(value) && value.length == 0))){
            helpText = 'This field is required';
            invalid = true;
        }
        return !invalid;
    }
    let helpText;
    let invalid = false;
    $: if (value && value.length > 0){
        invalid = false;
        helpText = null;
    }
    $: description = component.custom.description || 'Image';
    $: name = component.custom.name || 'Image';
    $: accept = component.custom.accept || 'image/*';

    let files = [];
    let isLoading = false;

    let dragOver = false;
    function handleDragEnter(evt) {
        evt.preventDefault();
        dragOver = true;
    }
    function handleDragOver(evt) {
        evt.preventDefault();
    }
    function handleDragLeave(evt) {
        evt.preventDefault();
        dragOver = false;
    }
    function handleDrop(evt) {
        evt.preventDefault();
        dragOver = false;
        if (evt.dataTransfer.items) {
            // Use DataTransferItemList interface to access the file(s)
            files = [];
            for (var i = 0; i < evt.dataTransfer.items.length; i++) {
                // If dropped items aren't files, reject them
                if (evt.dataTransfer.items[i].kind === "file") {
                    var file = evt.dataTransfer.items[i].getAsFile();
                    files.push(file);
                }
            }
        } else {
            // Use DataTransfer interface to access the file(s)
            files = evt.dataTransfer.files;
        }
        _processFiles(files);
    }

    function handleChange(evt) {
        if (this && this.files) {
            files = this.files;
            _processFiles(files);
        }
    }
    async function _processFiles(files) {
        if (files.length > 0){
            isLoading = true;
            for (let i = 0; i < files.length; i++) {
                let data = await _handleUpload(files[i]);
                value[value.length] = data;
            }
            isLoading = false;
        }
    }
    function _handleUpload(file){
        //console.log(loadImage);
        return new Promise((resolve, reject) => {
                    $callbacks.upload(file,name,description,component.id).then((data) => {
                        resolve(data);
                    }).catch(error=>{
                        reject(error);
                    });
            
        });
    }
    function handleDelete(evt){
        let id = evt.detail.value;
        if ($callbacks.delete){
            $callbacks.delete(id,component.id).then(()=>{
                value = value.filter(i=>{
                    return (i != id);
                });
            }).catch(error=>{
                console.warn(error);
            });
        }
        
    }
</script>
<MediaQuery query="(max-width: 480px)" let:matches>
    <div class="field" style="position:relative">
        {#if isLoading}
            <div class="cSpinner">
                <div class="loader" />
            </div>
        {/if}
        <label class="label" for={component.id}>
            {@html component.text}
            {#if !required}
                <span class="sublabel">optional</span>
            {/if}
        
        </label>
        <label
            class="cFile"
            on:drop={handleDrop}
            on:dragover={handleDragOver}
            on:dragenter={handleDragEnter}
            on:dragleave={handleDragLeave}
            class:dragOver
            >
            {#if matches}
                Click to upload a file
            {:else}
                Drag and drop or&nbsp;<a><u>browse</u></a>&nbsp;your files
            {/if}
            
            <input
                id={component.id}
                class="file-input"
                type="file"
                name="resume"
                {accept}
                on:change={handleChange}
                multiple
                disabled={isLoading}
            />
        </label>
            <div class="cUploadedFiles">
                {#each value as file (file)}
                    <div in:fly|local="{{ x: 200, duration: 500 }}" out:scale|local="{{ duration: 500 }}" animate:flip="{{duration: 200}}">
                        <FileInputItem {file} on:delete={handleDelete}/>
                    </div>
                {/each}
            </div>
            {#if invalid}
        <p class="help is-danger" transition:slide|local>{helpText}</p>
    {/if}
    </div>
</MediaQuery>
<style>
    .cFile {
        border: 1px dashed var(--border-color, #bbb);
        border-radius: var(--border-radius, 0.25rem);
        width: 100%;
        height: 5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        background: white;
    }
    .cFile input {
        display: none;
    }
    .cFile.dragOver {
        box-shadow: 0 0 2px 2px rgba(20, 88, 235, 0.5);
    }
    .cUploadedFiles {
        display: flex;
        flex-wrap: wrap;
    }
    .cSpinner {
        position: absolute;
        z-index: 3;
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #ffffffc9;
    }
    .cSpinner.loader {
        height: 2rem;
        width: 2rem;
    }
</style>
