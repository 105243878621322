<script>
    export let component;

    let modalOpen = false;
    let w,h;
</script>
<main>
    <figure class="image" class:landscape={w>h} class:portrait={w<h} bind:clientWidth={w} bind:clientHeight={h}>
        <img src={component.src} alt={component.text} on:click="{e=>modalOpen = true}" >
        <figcaption class="content">{@html component.text}</figcaption>
    </figure>

    {#if modalOpen}
        <div class="modal is-active">
            <div class="modal-background" on:click="{e=>modalOpen = false}"></div>
            <div class="modal-content">
            <p class="image ">
                <img src={component.src} alt={component.text}>
            </p>
            </div>
            <button class="modal-close is-large" aria-label="close" on:click="{e=>modalOpen = false}"></button>
        </div>
      {/if}
</main>
<style>
    .landscape{
        max-width:500px;
        height:auto;
    }
    .portrait{
        max-height:500px;
        width:auto;
    }
    figcaption{
        margin-top: .5rem;
    }
</style>