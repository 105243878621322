<script>
    import {responses} from '../../stores.js';
    import { slide } from "svelte/transition";

    export let component;
    export let value = [];
    export let required = true;
    export function isValid(){
        //console.log('test');
        if (required && value.length == 0){
            helpText = 'This field is required';
            invalid = true;
        }
        return !invalid;
    }
    let helpText;
    let invalid = false;
    $: if (value){
        invalid = false;
        helpText = null;
    }
    $: console.log(value);
    $: answers = (component && component.answers)?((Array.isArray(component.answers))?component.answers:component.answers.split(',').map((i)=>{return {label:i.trim(),value:i.trim()}})):[];
    $: isButton = (component && component.style == 'button');
    $: isTile = (component && component.style == 'tile');
    $: hasIds = (answers && answers.length > 0 && answers[0].id != null);
</script>
    <div class="field">
        <p class="label content">
            {@html component.text}
            {#if !required}
                <span class="sublabel">optional</span>
            {/if}
        </p>
        <div class="control" class:tiles={isTile}>
            {#each answers as answer, index (answer.value)}
                <div class:checkboxTile={isTile} class:checkboxButton={isButton}>
                    {#if hasIds}
                        <input id={answer.id} type = "checkbox" name={component.id} value={answer.value} bind:checked={$responses[answer.id]}/>
                        <label class:checkbox={!isButton && !isTile} class:cTile={isTile} class:tileImage={isTile && answer.src != null} style={(isTile && answer.src != null)?'background:url(\''+answer.src+'\')':''} class:button={isButton} class:is-fullwidth={isButton} class:is-primary={$responses[answer.id]} class:mt-1={isButton && index > 0} for={answer.id}>
                            {#if isTile && answer.src != null}
                                <span>{answer.label}</span>
                                {:else}
                                {answer.label}
                            {/if}
                        </label>                    
                    {:else}
                        <input id={component.id + index.toString()} type = "checkbox" name={component.id} value={answer.value} bind:group={value}/>
                        <label class:checkbox={!isButton && !isTile} class:cTile={isTile} class:tileImage={isTile && answer.src != null} style={(isTile && answer.src != null)?'background:url(\''+answer.src+'\')':''} class:button={isButton} class:is-fullwidth={isButton} class:is-primary={value.includes(answer.value)} class:mt-1={isButton && index > 0} for={component.id + index.toString()}>
                            {#if isTile && answer.src != null}
                                <span>{answer.label}</span>
                                {:else}
                                {answer.label}
                            {/if}
                            
                        </label>                    
                    {/if}

                </div>
            {/each}
        </div>
        {#if invalid}
        <p class="help is-danger" transition:slide|local>{helpText}</p>
    {/if}
    </div>
<style>
    .checkboxButton>input, .checkboxTile>input{
        display:none;
    }
    .checkboxButton .button.is-primary{
        transform:scale(.98);
        transition:transform .125s;
    }
    .tiles{
        display:flex;
        flex-wrap:wrap;
    }
    .checkboxTile{
        display:block;
        padding:.25rem;
    }
    .cTile{
        height:9rem;
        width:9rem;
        background-color:#fff;
        border-radius:.375rem;
        display:flex;
        position:relative;
        align-items: center;
        justify-content: center;
        border:1px solid #dbdbdb;
        cursor:pointer;
        transition:transform .1s;
    }
    .cTile:hover{
        border-color: #b5b5b5;
    }
    .cTile.is-primary{
        box-shadow: 0 0 0 2px #2b5597 inset;
        border-color: #2b5597;
        transform:scale(.95);
    }
    .cTile.tileImage{
        background-repeat:no-repeat !important;
        background-size:cover !important;
        background-position: center !important;
    }
    .cTile.tileImage>span{
        width: 100%;
        text-align: center;
        background-color: rgba(0, 0, 0, 0.64);
        color: white;
        position: absolute;
        bottom: 0;
        border-bottom-right-radius: .375rem;
        border-bottom-right-radius: .375rem;
        padding: .25rem;
    }
    .tileImage.is-primary>span{
        box-shadow: 0 -4px 0 -2px #2b5597 inset,-4px 0 0 -2px #2b5597 inset,4px 0 0 -2px #2b5597 inset;
    }
</style>