<script>
    // import { imask } from 'svelte-imask';
    import { slide } from "svelte/transition";

    export let component;
    export let value;
    export let disabled;
    export let required = true;
    export function isValid(){
        if (required && !value){
            helpText = 'This field is required';
            invalid = true;
        } else if (value && value.length != 10){
            helpText = 'Please enter a valid phone number';
            invalid = true;
        }
        return !invalid;
    }
    $: if (value && value.length == 10){
        invalid = false;
        helpText = null;
    }
    // $: if(value != null && value.length > 10){
    //     let v = value;
    //     if(v.startsWith('+1'))
    //         v = v.substring(2);
    //     else if (v.startsWith)
    //     v = v.replaceAll(/[^0-9]/gi,'');

    //     console.log(value,v)
    //     value = v;
    // }
    let helpText;
    let input;
    let invalid = false;

    function scrub(v){
        if (v==null || v === '') return '';

        v = v.trim();

        if(v.startsWith('+1'))
            v = v.substring(2);
        v = v.replaceAll(/[^0-9]/ig,'');

        if (v.length == 11 && v.charAt(0) === '1')
            v = v.substring(1);

        if (v.length > 10)
            v = v.substring(0,10);

        return v;
    }
    function mask(v){
        if (v==null || v === '') return '';
        v.trim();
        let ret = v.substring(0,3);

        if (v.length > 3){
            ret += ` ${v.substring(3,6)}`;

            if (v.length > 6){
                ret += `-${v.substring(6)}`;
            }

        }
        return ret;
    }

    let _value;
    $:{
        if (value?.length !== 10)
            value = scrub(value);
        _value = mask(value);
    }
    
    function completed(){
        invalid = false;
        helpText = null;
    }

    function handleInput(evt){
        let tmp = scrub(input.value);
        value = tmp;
        input.value = mask(tmp);
    }
    
</script>
<div class="field">
    <label class="label content" for={component.id}>
        {@html component.text}
        {#if !required}
                <span class="sublabel">optional</span>
            {/if}
    </label>
    <div class="control">
        
        <input  placeholder="### ###-####" class="input" class:is-danger={invalid} id={component.id} type="tel" value={_value} on:input={handleInput} {disabled} bind:this={input}/>
    </div>
    {#if invalid}
        <p class="help is-danger" transition:slide|local>{helpText}</p>
    {/if}
</div>