<script>
    import { slide } from "svelte/transition";

    export let component;
    export let value;
    export let required = true;
    export function isValid(){
        if (required && !value){
            helpText = 'This field is required';
            invalid = true;
        } else if (value){
            let valid = false;
            answers.forEach(i=>{
                helpText = 'Please select a valid answer';
                if (i.value == value)
                    valid = true;
            });
            if (!valid){
                invalid = true;
            }
        }
        return !invalid;
    }
    $: console.log(value);
    let helpText;
    let invalid = false;
    $: if (value){
        invalid = false;
        helpText = null;
    }
    $: answers = (component && component.answers)?((Array.isArray(component.answers))?component.answers:component.answers.split(',').map((i,idx)=>{return {label:i.trim(),value:i.trim(),id:component.id+idx.toString()}})):[];
    $: isButton = (component && component.style == 'button');
    $: isTile = (component && component.style == 'tile');
</script>
<div class="field">
    <p class="label content">
        {@html component.text}
        {#if !required}
                <span class="sublabel">optional</span>
            {/if}
    </p>
    <div class="control" class:tiles={isTile}>
        {#each answers as answer, index (answer.value)}
            <div class="control" class:radioTile={isTile} class:radioButton={isButton}>
                <input id={component.id + index.toString()} type = "radio" name={component.id} value={answer.value} bind:group={value}/>
                <label class:radio={!isButton && !isTile} class:cTile={isTile} class:tileImage={isTile && answer.src != null} style={(isTile && answer.src != null)?'background:url(\''+answer.src+'\')':''} class:button={isButton} class:is-fullwidth={isButton} class:is-primary={value == answer.value} class:mt-1={isButton && index > 0} for={component.id + index.toString()}>
                    {#if isTile && answer.src != null}
                        <span>{answer.label}</span>
                    {:else}
                        {answer.label}
                    {/if}
                </label>                    
            </div>
        {/each}
    </div>
    {#if invalid}
        <p class="help is-danger" transition:slide|local>{helpText}</p>
    {/if}
</div>
<style>
    .control.radioButton>input, .control.radioTile>input{
        display:none;
    }
    .radioButton .button.is-primary{
        transform:scale(.98);
        transition:transform .125s;
    }
    .radioTile{
        display:block;
        padding:.25rem;
    }
    .tiles{
        display:flex;
        flex-wrap:wrap;
    }
    .cTile{
        height:9rem;
        width:9rem;
        background-color:#fff;
        border-radius:.375rem;
        display:flex;
        position:relative;
        align-items: center;
        justify-content: center;
        border:1px solid #dbdbdb;
        cursor:pointer;
        transition:transform .1s;
    }
    .cTile:hover{
        border-color: #b5b5b5;
    }
    .cTile.is-primary{
        box-shadow: 0 0 0 2px #2b5597 inset;
        border-color: #2b5597;
        transform:scale(.95);
    }
    .cTile.tileImage{
        background-repeat:no-repeat !important;
        background-size:cover !important;
        background-position: center !important;
    }
    .cTile.tileImage>span{
        width: 100%;
        text-align: center;
        background-color: rgba(0, 0, 0, 0.64);
        color: white;
        position: absolute;
        bottom: 0;
        border-bottom-right-radius: .375rem;
        border-bottom-left-radius: .375rem;
        padding: .25rem;
    }
    .tileImage.is-primary>span{
        box-shadow: 0 -4px 0 -2px #2b5597 inset,-4px 0 0 -2px #2b5597 inset,4px 0 0 -2px #2b5597 inset;
    }
    
</style>