<script>
import { slide } from "svelte/transition";

    export let component;
    export let value;
    export let disabled;
    export let required = true;
    export function isValid(){
        if (required && !value){
            helpText = 'This field is required';
            invalid = true;
        } else if (value) {
            let v = parseInt(value);
            if (v == NaN){
                helpText = 'Please enter a valid number';
                invalid = true;
            } else if(component.min && v < component.min){
                helpText = 'Answer must be greater than or equal to ' + component.min;
                invalid = true;
            } else if(component.max && v > component.max){
                helpText = 'Answer must be less than or equal to ' + component.max;
                invalid = true;
            }
        }
        return !invalid;
    }
    let helpText;
    let invalid = false;
    let input;
    $: regex = (component.pattern)?new RegExp('^'+component.pattern+'$'):null;
    
    $: if (value || regex){
        if (value && regex && !regex.test(value)){
            invalid = true;
            helpText = component.errorMessage || 'Please enter a valid answer.';
        } else {
            invalid = false;
            helpText = null;
        }
        
    }
    

    function handleBeforeInput(evt){
        if (regex && evt.data){
            if (!regex.test(evt.data))
                evt.preventDefault();
        }
    }
    
</script>
<div class="field">
    <label class="label content" for={component.id}>
        {@html component.text}
        {#if !required}
                <span class="sublabel">optional</span>
            {/if}
    </label>
    <div class="control">
        {#if component.pattern}
        <input class="input" class:is-danger={invalid} id={component.id} type="text" inputmode="decimal" bind:value={value} {disabled} bind:this={input} on:beforeinput={handleBeforeInput} pattern={component.pattern}/>
        {:else}
        <input class="input" class:is-danger={invalid} id={component.id} type="text" inputmode="decimal" bind:value={value} {disabled} bind:this={input} on:beforeinput={handleBeforeInput}/>
        {/if}
        
    </div>
    {#if invalid}
        <p class="help is-danger" transition:slide|local>{helpText}</p>
    {/if}
</div>