<script>
    import { slide } from "svelte/transition";

    export let component;
    export let value;
    export let required = true;
    export function isValid(){
        if (required && !value){
            helpText = 'This field is required';
            invalid = true;
        }
        if (component.max && length > component.max){
            helpText = 'You\'ve exceeded the max ' + component.max + ' allowed characters';
            invalid = true;
        }
        return !invalid;
    }
    let helpText;
    let invalid = false;
    
    $: if (value){
            invalid = false;
            helpText = null;
    }
    $: length = (value)?value.length:0;
    $: counter = (component.max != null)?'<span class="sublabel">('+length + ' of ' + component.max+')</span>':'';
</script>
<div class="field">
    <label class="label content" for={component.id}>
        {@html component.text} 
        {#if !required}
                <span class="sublabel">optional</span>
            {/if}
        {@html counter}
    </label>
    <div class="control">
        <textarea class="textarea" class:is-danger={invalid} id={component.id} bind:value={value}/>
    </div>
    {#if invalid}
        <p class="help is-danger" transition:slide|local>{helpText}</p>
    {/if}
</div>