<script>
    export let progress = 0; //percent

    $: barStyle = ('width:'+progress + '%');
</script>
<div class="cProgress" >
    <div class="cProgressBar" style={barStyle}>
        {#if progress > 2}
        {progress}%
        {/if}
    </div>
</div>
<style>
    .cProgress{
        width:100%;
        height:1rem;
        
        background:#ededed;
        padding:none;
        margin:none;
    }
    .cProgressBar{
        height:100%;
        background-color:#2b5597;
        font-size:.8rem;
        line-height: .8rem;
        padding-top:.1rem;
        padding-right: .25rem;
        text-align: right;
        color:white;
        width:0%;
        transition:width .25s;
    }
    .cProgressBar:empty{
        padding: 0;
    }
</style>