//import App from './App.svelte';
import Survey from './Survey.svelte';
import { callbacks,responses,merge,global } from "./stores.js";

function App() {
	this.props = {};
	this.target;
	this._callbacks = {};
    this._merge = {};
    this._responses = {};
	this.app;
	this.setProps = (val)=>{
		this.props = val;
	}
	this.setProp = (key,val)=>{
		this.props[key] = val;
		if (this.app){
			let tmp = {};
			tmp[key] = val;
			this.app.$$set(tmp);
		}
	}
	this.setTarget = (val)=>{
		this.target = val;
	}
	this.setSurvey = (val) =>{
		this.props.survey = val;
		if (this.app){
			this.app.$$set({survey:val});
		}
	}
	this.setOptions = (val) =>{
		this.props.options = val;
		if (this.app){
			this.app.$$set({options:val});
		}
	}
	this.onFinish = (callback)=>{
		this._callbacks.finish = callback;
		if (this.app){
			callbacks.set(this._callbacks);
		}
	}
	this.onNext = (callback)=>{
		this._callbacks.next = callback;
		if (this.app){
			callbacks.set(this._callbacks);
		}
	}
	this.onBack = (callback)=>{
		this._callbacks.back = callback;
		if (this.app){
			callbacks.set(this._callbacks);
		}
	}
    this.on = (cb)=>{ //object of callbacks
        for (let key in cb){
            this._callbacks[key] = cb[key];
        }
        if (this.app){
            callbacks.set(this._callbacks);
		}
    }
	this.init = () =>{
		if (this.target){
			this.app = new Survey({
				target: this.target,
				props: this.props,
			});
		}
	};
  }
export default App;